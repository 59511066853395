import { Github, Linkedin } from "lucide-react";
import { Box, Typography, Link } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        textAlign: "center",
        boxSizing: "border-box",
        marginTop: "auto",
        paddingTop: 2,
        paddingBottom: 1,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.875rem",
          color: "#4a5568",
          marginBottom: "0.5rem",
          fontWeight: 600,
        }}
      >
        &copy; 2024 Metis LLM. All rights reserved.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Link
          href="https://github.com/AthenaAIC"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "#4a5568",
            transition: "color 0.3s ease",
            "&:hover": { color: "#63b3ed" },
          }}
        >
          <Github size={20} />
        </Link>
        <Link
          href="https://www.linkedin.com/company/athena-aic"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "#4a5568",
            transition: "color 0.3s ease",
            "&:hover": { color: "#63b3ed" },
          }}
        >
          <Linkedin size={20} />
        </Link>
      </Box>
    </Box>
  );
}
