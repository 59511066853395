export default function Nomura({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 1544 1142"
      height={height}
      width={width}
      fill="currentColor"
    >
      <g id="Clip-Path: Page 1">
        <g id="Page 1">
          <path
            id="Path 4"
            fillRule="evenodd"
            d="m786.4 1142l-306.5-774-277.5 774h-202l421.8-1141.3h231.1l436 1141.3zm757.4 0h-335.1l-274.4-774-43.3 100.6-104.6-277.1 76-190.8h245.3zm-872.6-143.9l-58.1 143.9h-187.2l144.3-392.4z"
          />
        </g>
      </g>
    </svg>
  );
}
