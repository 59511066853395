import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ChatMessageComponent from "./ChatMessages";
import { ChatInput } from "./ChatInput";
import { Recommendations } from "./Recommendations";
import { LoadingIndicator } from "./LoadingIndicator";
import { ChatMessage } from "../../generated/protos/chat";
import { ChatRequest } from "../../generated/protos/api";
import { useChatMessagesContext } from "../../context/ChatMessagesContext";
import { useDocumentContext } from "../../context/DocumentContext";
import { useMutation } from "@tanstack/react-query";
import chatMessages from "../../api/chat-messages";
import { BaseUrlContext } from "../../Contexts";
import { Box } from "@mui/material";
import { COLORS } from "../../styles/colors";

export default function Chat() {
  const { chatMessages: messages, setChatMessages: setMessages } =
    useChatMessagesContext();
  const { selectedDocuments } = useDocumentContext();

  const [recommendedQuestions, setRecommendedQuestions] = useState<string[]>(
    []
  );
  const [userInput, setUserInput] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);
  const [chatSessionId, setChatSessionId] = useState<string>(uuidv4());
  const baseUrl = useContext(BaseUrlContext);

  const {
    mutate: sendChatMessage,
    data: chatMessageResponse,
    error,
    isPending,
  } = useMutation({
    mutationFn: async (chatRequest: ChatRequest) =>
      chatMessages(baseUrl, chatRequest),
  });

  function clearChatHistory() {
    setMessages([]);
    setRecommendedQuestions([]);
    setChatSessionId(uuidv4());
  }

  useEffect(() => {
    if (chatMessageResponse) {
      const recommendedQuestions =
        chatMessageResponse.llmMessage?.followUpQuestions || [];
      setMessages((prevMessages) => [...prevMessages, chatMessageResponse]);
      setRecommendedQuestions(recommendedQuestions);
    }
  }, [chatMessageResponse]);

  useEffect(() => {
    if (userInput.trim() !== "" && submit) {
      const newChatMessage = ChatMessage.create({
        userMessage: { content: userInput },
        sessionId: chatSessionId,
        messageId: uuidv4(),
        asOfTimestamp: new Date(),
      });

      const updatedMessages = [...messages, newChatMessage];
      setMessages(updatedMessages);

      const chatRequest = ChatRequest.create({
        selectedDocumentMetadatas: selectedDocuments,
        chatHistory: updatedMessages,
      });

      sendChatMessage(chatRequest);
      setUserInput("");
    }
    setSubmit(false);
  }, [submit]);

  useEffect(() => {
    if (error) {
      console.log("Error fetching chat messages:", error);
    }
  }, [error]);

  function handleRecommendationClick(recommendation: string) {
    setUserInput(recommendation);
    setSubmit(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: 3,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-track": { background: "transparent" },
          "&::-webkit-scrollbar-thumb": {
            background: COLORS.grey[200],
            borderRadius: "6px",
            "&:hover": { background: COLORS.grey[300] },
          },
        }}
      >
        {messages.length === 0 && !isPending && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: COLORS.grey[500],
              fontSize: "1.25rem",
              fontWeight: 500,
            }}
          >
            Ask a question to get started
          </Box>
        )}
        {messages.map((message) => (
          <ChatMessageComponent key={message.messageId} message={message} />
        ))}
        {isPending && <LoadingIndicator />}
      </Box>
      <Box
        sx={{
          padding: 3,
          backdropFilter: "blur(8px)",
          position: "relative",
          zIndex: 2,
        }}
      >
        {recommendedQuestions.length > 0 && (
          <Recommendations
            recommendations={recommendedQuestions}
            handleRecommendationClick={handleRecommendationClick}
            isPending={isPending}
          />
        )}
        <ChatInput
          userInput={userInput}
          setUserInput={setUserInput}
          isLoading={isPending}
          onSubmit={() => setSubmit(!submit)}
          onClear={clearChatHistory}
        />
      </Box>
    </Box>
  );
}
