export default function DeutscheBank({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      height={height}
      width={width}
      fill="currentColor"
    >
      <path d="M3.375 3.375v17.25h17.25V3.375H3.375zM0 0h24v24H0V0zm5.25 18.225 9.15-12.45h4.35L9.6 18.225H5.25z" />
    </svg>
  );
}
