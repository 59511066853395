import { createContext, useContext, useState, ReactNode } from "react";

type Views = "Dashboard" | "Analysis";

type NavigationContextType = {
  activeComponent: Views;
  setActiveComponent: (component: Views) => void;
};

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined
);

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }
  return context;
};

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeComponent, setActiveComponent] = useState<Views>("Dashboard");

  return (
    <NavigationContext.Provider value={{ activeComponent, setActiveComponent }}>
      {children}
    </NavigationContext.Provider>
  );
};
