import {
  Document,
  DocumentMetadata,
  DocumentStatus,
} from "../generated/protos/document";

export default async function uploadDocument(
  baseUrl: string,
  username: string,
  fileName: string,
  binaryData: Uint8Array
) {
  const documentHash = await crypto.subtle.digest('SHA-256', binaryData)
    .then(hashBuffer => Array.from(new Uint8Array(hashBuffer))
    .map(b => b.toString(16).padStart(2, '0'))
      .join(''));
  const userDocumentId = `${documentHash}_${username}`;
  const documentMetadata = DocumentMetadata.create({
    documentId: userDocumentId,
    documentName: fileName,
    documentStatus: DocumentStatus.DOCUMENT_STATUS_UNSPECIFIED,
    userEmail: username,
    asOfTimestamp: new Date(),
  });
  const message = Document.create({
    documentMetadata: documentMetadata,
    documentBytes: binaryData,
  });

  const encodedMessage = Document.encode(message).finish();

  const response = await fetch(`${baseUrl}/documents`, {
    method: "POST",
    headers: {
      "Content-Type": "application/octet-stream",
    },
    body: encodedMessage,
  });

  if (!response.ok) {
    throw new Error(`File upload failed with status ${response.status}`);
  }

  return await response.json();
}
