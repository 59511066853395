// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/chunk.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "finllm";

export enum Company {
  UNSPECIFIED = 0,
  JP_MORGAN_CHASE = 1,
  BANK_OF_AMERICA = 2,
  GOLDMAN_SACHS = 3,
  MORGAN_STANLEY = 4,
  WELLS_FARGO = 5,
  CITIGROUP = 6,
  BARCLAYS = 7,
  DEUTSCHE_BANK = 8,
  CREDIT_SUISSE = 9,
  UBS = 10,
  HSBC = 11,
  NOMURA = 12,
  CONSENSUS = 13,
  UNRECOGNIZED = -1,
}

export function companyFromJSON(object: any): Company {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return Company.UNSPECIFIED;
    case 1:
    case "JP_MORGAN_CHASE":
      return Company.JP_MORGAN_CHASE;
    case 2:
    case "BANK_OF_AMERICA":
      return Company.BANK_OF_AMERICA;
    case 3:
    case "GOLDMAN_SACHS":
      return Company.GOLDMAN_SACHS;
    case 4:
    case "MORGAN_STANLEY":
      return Company.MORGAN_STANLEY;
    case 5:
    case "WELLS_FARGO":
      return Company.WELLS_FARGO;
    case 6:
    case "CITIGROUP":
      return Company.CITIGROUP;
    case 7:
    case "BARCLAYS":
      return Company.BARCLAYS;
    case 8:
    case "DEUTSCHE_BANK":
      return Company.DEUTSCHE_BANK;
    case 9:
    case "CREDIT_SUISSE":
      return Company.CREDIT_SUISSE;
    case 10:
    case "UBS":
      return Company.UBS;
    case 11:
    case "HSBC":
      return Company.HSBC;
    case 12:
    case "NOMURA":
      return Company.NOMURA;
    case 13:
    case "CONSENSUS":
      return Company.CONSENSUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Company.UNRECOGNIZED;
  }
}

export function companyToJSON(object: Company): string {
  switch (object) {
    case Company.UNSPECIFIED:
      return "UNSPECIFIED";
    case Company.JP_MORGAN_CHASE:
      return "JP_MORGAN_CHASE";
    case Company.BANK_OF_AMERICA:
      return "BANK_OF_AMERICA";
    case Company.GOLDMAN_SACHS:
      return "GOLDMAN_SACHS";
    case Company.MORGAN_STANLEY:
      return "MORGAN_STANLEY";
    case Company.WELLS_FARGO:
      return "WELLS_FARGO";
    case Company.CITIGROUP:
      return "CITIGROUP";
    case Company.BARCLAYS:
      return "BARCLAYS";
    case Company.DEUTSCHE_BANK:
      return "DEUTSCHE_BANK";
    case Company.CREDIT_SUISSE:
      return "CREDIT_SUISSE";
    case Company.UBS:
      return "UBS";
    case Company.HSBC:
      return "HSBC";
    case Company.NOMURA:
      return "NOMURA";
    case Company.CONSENSUS:
      return "CONSENSUS";
    case Company.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Unit {
  UNIT_UNSPECIFIED = 0,
  UNIT_DOLLAR = 1,
  UNIT_PERCENTAGE = 2,
  UNIT_BPS = 3,
  UNIT_EXCHANGE_RATE = 4,
  UNRECOGNIZED = -1,
}

export function unitFromJSON(object: any): Unit {
  switch (object) {
    case 0:
    case "UNIT_UNSPECIFIED":
      return Unit.UNIT_UNSPECIFIED;
    case 1:
    case "UNIT_DOLLAR":
      return Unit.UNIT_DOLLAR;
    case 2:
    case "UNIT_PERCENTAGE":
      return Unit.UNIT_PERCENTAGE;
    case 3:
    case "UNIT_BPS":
      return Unit.UNIT_BPS;
    case 4:
    case "UNIT_EXCHANGE_RATE":
      return Unit.UNIT_EXCHANGE_RATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Unit.UNRECOGNIZED;
  }
}

export function unitToJSON(object: Unit): string {
  switch (object) {
    case Unit.UNIT_UNSPECIFIED:
      return "UNIT_UNSPECIFIED";
    case Unit.UNIT_DOLLAR:
      return "UNIT_DOLLAR";
    case Unit.UNIT_PERCENTAGE:
      return "UNIT_PERCENTAGE";
    case Unit.UNIT_BPS:
      return "UNIT_BPS";
    case Unit.UNIT_EXCHANGE_RATE:
      return "UNIT_EXCHANGE_RATE";
    case Unit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Page {
  pageBytes: Uint8Array;
  pageText: string;
  bytesFormat: Page_BytesFormat;
}

export enum Page_BytesFormat {
  UNSPECIFIED = 0,
  PDF = 1,
  JPEG = 2,
  UNRECOGNIZED = -1,
}

export function page_BytesFormatFromJSON(object: any): Page_BytesFormat {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return Page_BytesFormat.UNSPECIFIED;
    case 1:
    case "PDF":
      return Page_BytesFormat.PDF;
    case 2:
    case "JPEG":
      return Page_BytesFormat.JPEG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Page_BytesFormat.UNRECOGNIZED;
  }
}

export function page_BytesFormatToJSON(object: Page_BytesFormat): string {
  switch (object) {
    case Page_BytesFormat.UNSPECIFIED:
      return "UNSPECIFIED";
    case Page_BytesFormat.PDF:
      return "PDF";
    case Page_BytesFormat.JPEG:
      return "JPEG";
    case Page_BytesFormat.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Text {
  text: string;
  keywords: string[];
}

export interface Row {
  values: string[];
}

export interface Table {
  title: string;
  columnNames: Row | undefined;
  data: Row[];
  context: string;
}

export interface Metadata {
  pdfName: string;
  authors: string[];
  publishingOrganization: Company;
  publishTimestamp: Date | undefined;
}

export interface Chunk {
  chunkId: string;
  metadata: Metadata | undefined;
  pageNum: number;
  page?: Page | undefined;
  text?: Text | undefined;
  table?: Table | undefined;
  entityRelationship?: EntityRelationship | undefined;
  metaRelationship?: MetaEntityRelationship | undefined;
}

export interface ValueChange {
  directionalChange?: ValueChange_ValueChangeDirection | undefined;
  absoluteValue?: string | undefined;
  relativeChange?: string | undefined;
  unit?: Unit | undefined;
}

export enum ValueChange_ValueChangeDirection {
  VALUE_CHANGE_UNSPECIFIED = 0,
  VALUE_CHANGE_NEUTRAL = 1,
  VALUE_CHANGE_INCREASE = 2,
  VALUE_CHANGE_DECREASE = 3,
  UNRECOGNIZED = -1,
}

export function valueChange_ValueChangeDirectionFromJSON(object: any): ValueChange_ValueChangeDirection {
  switch (object) {
    case 0:
    case "VALUE_CHANGE_UNSPECIFIED":
      return ValueChange_ValueChangeDirection.VALUE_CHANGE_UNSPECIFIED;
    case 1:
    case "VALUE_CHANGE_NEUTRAL":
      return ValueChange_ValueChangeDirection.VALUE_CHANGE_NEUTRAL;
    case 2:
    case "VALUE_CHANGE_INCREASE":
      return ValueChange_ValueChangeDirection.VALUE_CHANGE_INCREASE;
    case 3:
    case "VALUE_CHANGE_DECREASE":
      return ValueChange_ValueChangeDirection.VALUE_CHANGE_DECREASE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ValueChange_ValueChangeDirection.UNRECOGNIZED;
  }
}

export function valueChange_ValueChangeDirectionToJSON(object: ValueChange_ValueChangeDirection): string {
  switch (object) {
    case ValueChange_ValueChangeDirection.VALUE_CHANGE_UNSPECIFIED:
      return "VALUE_CHANGE_UNSPECIFIED";
    case ValueChange_ValueChangeDirection.VALUE_CHANGE_NEUTRAL:
      return "VALUE_CHANGE_NEUTRAL";
    case ValueChange_ValueChangeDirection.VALUE_CHANGE_INCREASE:
      return "VALUE_CHANGE_INCREASE";
    case ValueChange_ValueChangeDirection.VALUE_CHANGE_DECREASE:
      return "VALUE_CHANGE_DECREASE";
    case ValueChange_ValueChangeDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Value {
  value: string;
  unit: Unit;
  qualifier: string;
  timeComparison: Value_TimeComparison;
  adjustmentType: Value_AdjustmentType;
}

export enum Value_TimeComparison {
  TIME_COMPARISON_UNSPECIFIED = 0,
  TIME_COMPARISON_MONTH_OVER_MONTH = 1,
  TIME_COMPARISON_QUARTER_OVER_QUARTER = 2,
  TIME_COMPARISON_YEAR_OVER_YEAR = 3,
  TIME_COMPARISON_QUARTERLY_YEAR_OVER_YEAR = 4,
  UNRECOGNIZED = -1,
}

export function value_TimeComparisonFromJSON(object: any): Value_TimeComparison {
  switch (object) {
    case 0:
    case "TIME_COMPARISON_UNSPECIFIED":
      return Value_TimeComparison.TIME_COMPARISON_UNSPECIFIED;
    case 1:
    case "TIME_COMPARISON_MONTH_OVER_MONTH":
      return Value_TimeComparison.TIME_COMPARISON_MONTH_OVER_MONTH;
    case 2:
    case "TIME_COMPARISON_QUARTER_OVER_QUARTER":
      return Value_TimeComparison.TIME_COMPARISON_QUARTER_OVER_QUARTER;
    case 3:
    case "TIME_COMPARISON_YEAR_OVER_YEAR":
      return Value_TimeComparison.TIME_COMPARISON_YEAR_OVER_YEAR;
    case 4:
    case "TIME_COMPARISON_QUARTERLY_YEAR_OVER_YEAR":
      return Value_TimeComparison.TIME_COMPARISON_QUARTERLY_YEAR_OVER_YEAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_TimeComparison.UNRECOGNIZED;
  }
}

export function value_TimeComparisonToJSON(object: Value_TimeComparison): string {
  switch (object) {
    case Value_TimeComparison.TIME_COMPARISON_UNSPECIFIED:
      return "TIME_COMPARISON_UNSPECIFIED";
    case Value_TimeComparison.TIME_COMPARISON_MONTH_OVER_MONTH:
      return "TIME_COMPARISON_MONTH_OVER_MONTH";
    case Value_TimeComparison.TIME_COMPARISON_QUARTER_OVER_QUARTER:
      return "TIME_COMPARISON_QUARTER_OVER_QUARTER";
    case Value_TimeComparison.TIME_COMPARISON_YEAR_OVER_YEAR:
      return "TIME_COMPARISON_YEAR_OVER_YEAR";
    case Value_TimeComparison.TIME_COMPARISON_QUARTERLY_YEAR_OVER_YEAR:
      return "TIME_COMPARISON_QUARTERLY_YEAR_OVER_YEAR";
    case Value_TimeComparison.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Value_AdjustmentType {
  ADJUSTMENT_TYPE_UNSPECIFIED = 0,
  ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED = 1,
  ADJUSTMENT_TYPE_NON_SEASONALLY_ADJUSTED = 2,
  ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED_ANNUALIZED_RATE = 3,
  ADJUSTMENT_TYPE_ANNUALIZED_RATE = 4,
  UNRECOGNIZED = -1,
}

export function value_AdjustmentTypeFromJSON(object: any): Value_AdjustmentType {
  switch (object) {
    case 0:
    case "ADJUSTMENT_TYPE_UNSPECIFIED":
      return Value_AdjustmentType.ADJUSTMENT_TYPE_UNSPECIFIED;
    case 1:
    case "ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED":
      return Value_AdjustmentType.ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED;
    case 2:
    case "ADJUSTMENT_TYPE_NON_SEASONALLY_ADJUSTED":
      return Value_AdjustmentType.ADJUSTMENT_TYPE_NON_SEASONALLY_ADJUSTED;
    case 3:
    case "ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED_ANNUALIZED_RATE":
      return Value_AdjustmentType.ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED_ANNUALIZED_RATE;
    case 4:
    case "ADJUSTMENT_TYPE_ANNUALIZED_RATE":
      return Value_AdjustmentType.ADJUSTMENT_TYPE_ANNUALIZED_RATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_AdjustmentType.UNRECOGNIZED;
  }
}

export function value_AdjustmentTypeToJSON(object: Value_AdjustmentType): string {
  switch (object) {
    case Value_AdjustmentType.ADJUSTMENT_TYPE_UNSPECIFIED:
      return "ADJUSTMENT_TYPE_UNSPECIFIED";
    case Value_AdjustmentType.ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED:
      return "ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED";
    case Value_AdjustmentType.ADJUSTMENT_TYPE_NON_SEASONALLY_ADJUSTED:
      return "ADJUSTMENT_TYPE_NON_SEASONALLY_ADJUSTED";
    case Value_AdjustmentType.ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED_ANNUALIZED_RATE:
      return "ADJUSTMENT_TYPE_SEASONALLY_ADJUSTED_ANNUALIZED_RATE";
    case Value_AdjustmentType.ADJUSTMENT_TYPE_ANNUALIZED_RATE:
      return "ADJUSTMENT_TYPE_ANNUALIZED_RATE";
    case Value_AdjustmentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Trade {
  unit: Unit;
  entryValue: string;
  targetValue: string;
  stopLossValue: string;
}

export interface EntityRelationship {
  relationshipId: string;
  timeSlice: TimeSlice | undefined;
  fromEntity: Entity | undefined;
  toEntity: Entity | undefined;
  sourceText: string;
  sourceChunkId: string;
  valueChange?: ValueChange | undefined;
  value?: Value | undefined;
  trade?: Trade | undefined;
}

export interface MetaEntityRelationship {
  metaRelationshipId: string;
  fromEntityRelationship: EntityRelationship | undefined;
  toEntityRelationship: EntityRelationship | undefined;
  explanationText: string;
  causalityRelationship?: CausalityRelationship | undefined;
  correlationRelationship?: CorrelationRelationship | undefined;
}

export interface CausalityRelationship {
}

export interface CorrelationRelationship {
}

export interface TimeSlice {
  startTimestamp?: Date | undefined;
  endTimestamp?: Date | undefined;
  asOfTimestamp: Date | undefined;
}

export interface Entity {
  entityName: string;
  entityType: Entity_EntityType;
  entitySubcomponents: string[];
}

export enum Entity_EntityType {
  ENTITY_TYPE_UNSPECIFIED = 0,
  ENTITY_TYPE_ACTOR = 1,
  ENTITY_TYPE_ASSET = 2,
  ENTITY_TYPE_METRIC = 3,
  ENTITY_TYPE_EVENT = 4,
  ENTITY_TYPE_TRADE = 5,
  UNRECOGNIZED = -1,
}

export function entity_EntityTypeFromJSON(object: any): Entity_EntityType {
  switch (object) {
    case 0:
    case "ENTITY_TYPE_UNSPECIFIED":
      return Entity_EntityType.ENTITY_TYPE_UNSPECIFIED;
    case 1:
    case "ENTITY_TYPE_ACTOR":
      return Entity_EntityType.ENTITY_TYPE_ACTOR;
    case 2:
    case "ENTITY_TYPE_ASSET":
      return Entity_EntityType.ENTITY_TYPE_ASSET;
    case 3:
    case "ENTITY_TYPE_METRIC":
      return Entity_EntityType.ENTITY_TYPE_METRIC;
    case 4:
    case "ENTITY_TYPE_EVENT":
      return Entity_EntityType.ENTITY_TYPE_EVENT;
    case 5:
    case "ENTITY_TYPE_TRADE":
      return Entity_EntityType.ENTITY_TYPE_TRADE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Entity_EntityType.UNRECOGNIZED;
  }
}

export function entity_EntityTypeToJSON(object: Entity_EntityType): string {
  switch (object) {
    case Entity_EntityType.ENTITY_TYPE_UNSPECIFIED:
      return "ENTITY_TYPE_UNSPECIFIED";
    case Entity_EntityType.ENTITY_TYPE_ACTOR:
      return "ENTITY_TYPE_ACTOR";
    case Entity_EntityType.ENTITY_TYPE_ASSET:
      return "ENTITY_TYPE_ASSET";
    case Entity_EntityType.ENTITY_TYPE_METRIC:
      return "ENTITY_TYPE_METRIC";
    case Entity_EntityType.ENTITY_TYPE_EVENT:
      return "ENTITY_TYPE_EVENT";
    case Entity_EntityType.ENTITY_TYPE_TRADE:
      return "ENTITY_TYPE_TRADE";
    case Entity_EntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBasePage(): Page {
  return { pageBytes: new Uint8Array(0), pageText: "", bytesFormat: 0 };
}

export const Page: MessageFns<Page> = {
  encode(message: Page, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageBytes.length !== 0) {
      writer.uint32(10).bytes(message.pageBytes);
    }
    if (message.pageText !== "") {
      writer.uint32(18).string(message.pageText);
    }
    if (message.bytesFormat !== 0) {
      writer.uint32(24).int32(message.bytesFormat);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Page {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageBytes = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageText = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesFormat = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Page {
    return {
      pageBytes: isSet(object.pageBytes) ? bytesFromBase64(object.pageBytes) : new Uint8Array(0),
      pageText: isSet(object.pageText) ? globalThis.String(object.pageText) : "",
      bytesFormat: isSet(object.bytesFormat) ? page_BytesFormatFromJSON(object.bytesFormat) : 0,
    };
  },

  toJSON(message: Page): unknown {
    const obj: any = {};
    if (message.pageBytes.length !== 0) {
      obj.pageBytes = base64FromBytes(message.pageBytes);
    }
    if (message.pageText !== "") {
      obj.pageText = message.pageText;
    }
    if (message.bytesFormat !== 0) {
      obj.bytesFormat = page_BytesFormatToJSON(message.bytesFormat);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Page>, I>>(base?: I): Page {
    return Page.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Page>, I>>(object: I): Page {
    const message = createBasePage();
    message.pageBytes = object.pageBytes ?? new Uint8Array(0);
    message.pageText = object.pageText ?? "";
    message.bytesFormat = object.bytesFormat ?? 0;
    return message;
  },
};

function createBaseText(): Text {
  return { text: "", keywords: [] };
}

export const Text: MessageFns<Text> = {
  encode(message: Text, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.text !== "") {
      writer.uint32(10).string(message.text);
    }
    for (const v of message.keywords) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Text {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.keywords.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Text {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      keywords: globalThis.Array.isArray(object?.keywords) ? object.keywords.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Text): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.keywords?.length) {
      obj.keywords = message.keywords;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Text>, I>>(base?: I): Text {
    return Text.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Text>, I>>(object: I): Text {
    const message = createBaseText();
    message.text = object.text ?? "";
    message.keywords = object.keywords?.map((e) => e) || [];
    return message;
  },
};

function createBaseRow(): Row {
  return { values: [] };
}

export const Row: MessageFns<Row> = {
  encode(message: Row, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.values) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Row {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Row {
    return {
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Row): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Row>, I>>(base?: I): Row {
    return Row.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Row>, I>>(object: I): Row {
    const message = createBaseRow();
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseTable(): Table {
  return { title: "", columnNames: undefined, data: [], context: "" };
}

export const Table: MessageFns<Table> = {
  encode(message: Table, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.columnNames !== undefined) {
      Row.encode(message.columnNames, writer.uint32(18).fork()).join();
    }
    for (const v of message.data) {
      Row.encode(v!, writer.uint32(26).fork()).join();
    }
    if (message.context !== "") {
      writer.uint32(34).string(message.context);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Table {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.columnNames = Row.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data.push(Row.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.context = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Table {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      columnNames: isSet(object.columnNames) ? Row.fromJSON(object.columnNames) : undefined,
      data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => Row.fromJSON(e)) : [],
      context: isSet(object.context) ? globalThis.String(object.context) : "",
    };
  },

  toJSON(message: Table): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.columnNames !== undefined) {
      obj.columnNames = Row.toJSON(message.columnNames);
    }
    if (message.data?.length) {
      obj.data = message.data.map((e) => Row.toJSON(e));
    }
    if (message.context !== "") {
      obj.context = message.context;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Table>, I>>(base?: I): Table {
    return Table.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Table>, I>>(object: I): Table {
    const message = createBaseTable();
    message.title = object.title ?? "";
    message.columnNames = (object.columnNames !== undefined && object.columnNames !== null)
      ? Row.fromPartial(object.columnNames)
      : undefined;
    message.data = object.data?.map((e) => Row.fromPartial(e)) || [];
    message.context = object.context ?? "";
    return message;
  },
};

function createBaseMetadata(): Metadata {
  return { pdfName: "", authors: [], publishingOrganization: 0, publishTimestamp: undefined };
}

export const Metadata: MessageFns<Metadata> = {
  encode(message: Metadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pdfName !== "") {
      writer.uint32(10).string(message.pdfName);
    }
    for (const v of message.authors) {
      writer.uint32(18).string(v!);
    }
    if (message.publishingOrganization !== 0) {
      writer.uint32(24).int32(message.publishingOrganization);
    }
    if (message.publishTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.publishTimestamp), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Metadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdfName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authors.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.publishingOrganization = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.publishTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Metadata {
    return {
      pdfName: isSet(object.pdfName) ? globalThis.String(object.pdfName) : "",
      authors: globalThis.Array.isArray(object?.authors) ? object.authors.map((e: any) => globalThis.String(e)) : [],
      publishingOrganization: isSet(object.publishingOrganization) ? companyFromJSON(object.publishingOrganization) : 0,
      publishTimestamp: isSet(object.publishTimestamp) ? fromJsonTimestamp(object.publishTimestamp) : undefined,
    };
  },

  toJSON(message: Metadata): unknown {
    const obj: any = {};
    if (message.pdfName !== "") {
      obj.pdfName = message.pdfName;
    }
    if (message.authors?.length) {
      obj.authors = message.authors;
    }
    if (message.publishingOrganization !== 0) {
      obj.publishingOrganization = companyToJSON(message.publishingOrganization);
    }
    if (message.publishTimestamp !== undefined) {
      obj.publishTimestamp = message.publishTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Metadata>, I>>(base?: I): Metadata {
    return Metadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Metadata>, I>>(object: I): Metadata {
    const message = createBaseMetadata();
    message.pdfName = object.pdfName ?? "";
    message.authors = object.authors?.map((e) => e) || [];
    message.publishingOrganization = object.publishingOrganization ?? 0;
    message.publishTimestamp = object.publishTimestamp ?? undefined;
    return message;
  },
};

function createBaseChunk(): Chunk {
  return {
    chunkId: "",
    metadata: undefined,
    pageNum: 0,
    page: undefined,
    text: undefined,
    table: undefined,
    entityRelationship: undefined,
    metaRelationship: undefined,
  };
}

export const Chunk: MessageFns<Chunk> = {
  encode(message: Chunk, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chunkId !== "") {
      writer.uint32(10).string(message.chunkId);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(18).fork()).join();
    }
    if (message.pageNum !== 0) {
      writer.uint32(24).int32(message.pageNum);
    }
    if (message.page !== undefined) {
      Page.encode(message.page, writer.uint32(34).fork()).join();
    }
    if (message.text !== undefined) {
      Text.encode(message.text, writer.uint32(42).fork()).join();
    }
    if (message.table !== undefined) {
      Table.encode(message.table, writer.uint32(50).fork()).join();
    }
    if (message.entityRelationship !== undefined) {
      EntityRelationship.encode(message.entityRelationship, writer.uint32(58).fork()).join();
    }
    if (message.metaRelationship !== undefined) {
      MetaEntityRelationship.encode(message.metaRelationship, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Chunk {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChunk();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chunkId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageNum = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.page = Page.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.text = Text.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.table = Table.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.entityRelationship = EntityRelationship.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.metaRelationship = MetaEntityRelationship.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Chunk {
    return {
      chunkId: isSet(object.chunkId) ? globalThis.String(object.chunkId) : "",
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      pageNum: isSet(object.pageNum) ? globalThis.Number(object.pageNum) : 0,
      page: isSet(object.page) ? Page.fromJSON(object.page) : undefined,
      text: isSet(object.text) ? Text.fromJSON(object.text) : undefined,
      table: isSet(object.table) ? Table.fromJSON(object.table) : undefined,
      entityRelationship: isSet(object.entityRelationship)
        ? EntityRelationship.fromJSON(object.entityRelationship)
        : undefined,
      metaRelationship: isSet(object.metaRelationship)
        ? MetaEntityRelationship.fromJSON(object.metaRelationship)
        : undefined,
    };
  },

  toJSON(message: Chunk): unknown {
    const obj: any = {};
    if (message.chunkId !== "") {
      obj.chunkId = message.chunkId;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    if (message.page !== undefined) {
      obj.page = Page.toJSON(message.page);
    }
    if (message.text !== undefined) {
      obj.text = Text.toJSON(message.text);
    }
    if (message.table !== undefined) {
      obj.table = Table.toJSON(message.table);
    }
    if (message.entityRelationship !== undefined) {
      obj.entityRelationship = EntityRelationship.toJSON(message.entityRelationship);
    }
    if (message.metaRelationship !== undefined) {
      obj.metaRelationship = MetaEntityRelationship.toJSON(message.metaRelationship);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Chunk>, I>>(base?: I): Chunk {
    return Chunk.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Chunk>, I>>(object: I): Chunk {
    const message = createBaseChunk();
    message.chunkId = object.chunkId ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.pageNum = object.pageNum ?? 0;
    message.page = (object.page !== undefined && object.page !== null) ? Page.fromPartial(object.page) : undefined;
    message.text = (object.text !== undefined && object.text !== null) ? Text.fromPartial(object.text) : undefined;
    message.table = (object.table !== undefined && object.table !== null) ? Table.fromPartial(object.table) : undefined;
    message.entityRelationship = (object.entityRelationship !== undefined && object.entityRelationship !== null)
      ? EntityRelationship.fromPartial(object.entityRelationship)
      : undefined;
    message.metaRelationship = (object.metaRelationship !== undefined && object.metaRelationship !== null)
      ? MetaEntityRelationship.fromPartial(object.metaRelationship)
      : undefined;
    return message;
  },
};

function createBaseValueChange(): ValueChange {
  return { directionalChange: undefined, absoluteValue: undefined, relativeChange: undefined, unit: undefined };
}

export const ValueChange: MessageFns<ValueChange> = {
  encode(message: ValueChange, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.directionalChange !== undefined) {
      writer.uint32(8).int32(message.directionalChange);
    }
    if (message.absoluteValue !== undefined) {
      writer.uint32(18).string(message.absoluteValue);
    }
    if (message.relativeChange !== undefined) {
      writer.uint32(26).string(message.relativeChange);
    }
    if (message.unit !== undefined) {
      writer.uint32(32).int32(message.unit);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ValueChange {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValueChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.directionalChange = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.absoluteValue = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.relativeChange = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValueChange {
    return {
      directionalChange: isSet(object.directionalChange)
        ? valueChange_ValueChangeDirectionFromJSON(object.directionalChange)
        : undefined,
      absoluteValue: isSet(object.absoluteValue) ? globalThis.String(object.absoluteValue) : undefined,
      relativeChange: isSet(object.relativeChange) ? globalThis.String(object.relativeChange) : undefined,
      unit: isSet(object.unit) ? unitFromJSON(object.unit) : undefined,
    };
  },

  toJSON(message: ValueChange): unknown {
    const obj: any = {};
    if (message.directionalChange !== undefined) {
      obj.directionalChange = valueChange_ValueChangeDirectionToJSON(message.directionalChange);
    }
    if (message.absoluteValue !== undefined) {
      obj.absoluteValue = message.absoluteValue;
    }
    if (message.relativeChange !== undefined) {
      obj.relativeChange = message.relativeChange;
    }
    if (message.unit !== undefined) {
      obj.unit = unitToJSON(message.unit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValueChange>, I>>(base?: I): ValueChange {
    return ValueChange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValueChange>, I>>(object: I): ValueChange {
    const message = createBaseValueChange();
    message.directionalChange = object.directionalChange ?? undefined;
    message.absoluteValue = object.absoluteValue ?? undefined;
    message.relativeChange = object.relativeChange ?? undefined;
    message.unit = object.unit ?? undefined;
    return message;
  },
};

function createBaseValue(): Value {
  return { value: "", unit: 0, qualifier: "", timeComparison: 0, adjustmentType: 0 };
}

export const Value: MessageFns<Value> = {
  encode(message: Value, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.unit !== 0) {
      writer.uint32(16).int32(message.unit);
    }
    if (message.qualifier !== "") {
      writer.uint32(26).string(message.qualifier);
    }
    if (message.timeComparison !== 0) {
      writer.uint32(32).int32(message.timeComparison);
    }
    if (message.adjustmentType !== 0) {
      writer.uint32(40).int32(message.adjustmentType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Value {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.qualifier = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeComparison = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.adjustmentType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Value {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      unit: isSet(object.unit) ? unitFromJSON(object.unit) : 0,
      qualifier: isSet(object.qualifier) ? globalThis.String(object.qualifier) : "",
      timeComparison: isSet(object.timeComparison) ? value_TimeComparisonFromJSON(object.timeComparison) : 0,
      adjustmentType: isSet(object.adjustmentType) ? value_AdjustmentTypeFromJSON(object.adjustmentType) : 0,
    };
  },

  toJSON(message: Value): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.unit !== 0) {
      obj.unit = unitToJSON(message.unit);
    }
    if (message.qualifier !== "") {
      obj.qualifier = message.qualifier;
    }
    if (message.timeComparison !== 0) {
      obj.timeComparison = value_TimeComparisonToJSON(message.timeComparison);
    }
    if (message.adjustmentType !== 0) {
      obj.adjustmentType = value_AdjustmentTypeToJSON(message.adjustmentType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Value>, I>>(base?: I): Value {
    return Value.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Value>, I>>(object: I): Value {
    const message = createBaseValue();
    message.value = object.value ?? "";
    message.unit = object.unit ?? 0;
    message.qualifier = object.qualifier ?? "";
    message.timeComparison = object.timeComparison ?? 0;
    message.adjustmentType = object.adjustmentType ?? 0;
    return message;
  },
};

function createBaseTrade(): Trade {
  return { unit: 0, entryValue: "", targetValue: "", stopLossValue: "" };
}

export const Trade: MessageFns<Trade> = {
  encode(message: Trade, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.unit !== 0) {
      writer.uint32(8).int32(message.unit);
    }
    if (message.entryValue !== "") {
      writer.uint32(18).string(message.entryValue);
    }
    if (message.targetValue !== "") {
      writer.uint32(26).string(message.targetValue);
    }
    if (message.stopLossValue !== "") {
      writer.uint32(34).string(message.stopLossValue);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Trade {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entryValue = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetValue = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stopLossValue = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Trade {
    return {
      unit: isSet(object.unit) ? unitFromJSON(object.unit) : 0,
      entryValue: isSet(object.entryValue) ? globalThis.String(object.entryValue) : "",
      targetValue: isSet(object.targetValue) ? globalThis.String(object.targetValue) : "",
      stopLossValue: isSet(object.stopLossValue) ? globalThis.String(object.stopLossValue) : "",
    };
  },

  toJSON(message: Trade): unknown {
    const obj: any = {};
    if (message.unit !== 0) {
      obj.unit = unitToJSON(message.unit);
    }
    if (message.entryValue !== "") {
      obj.entryValue = message.entryValue;
    }
    if (message.targetValue !== "") {
      obj.targetValue = message.targetValue;
    }
    if (message.stopLossValue !== "") {
      obj.stopLossValue = message.stopLossValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Trade>, I>>(base?: I): Trade {
    return Trade.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Trade>, I>>(object: I): Trade {
    const message = createBaseTrade();
    message.unit = object.unit ?? 0;
    message.entryValue = object.entryValue ?? "";
    message.targetValue = object.targetValue ?? "";
    message.stopLossValue = object.stopLossValue ?? "";
    return message;
  },
};

function createBaseEntityRelationship(): EntityRelationship {
  return {
    relationshipId: "",
    timeSlice: undefined,
    fromEntity: undefined,
    toEntity: undefined,
    sourceText: "",
    sourceChunkId: "",
    valueChange: undefined,
    value: undefined,
    trade: undefined,
  };
}

export const EntityRelationship: MessageFns<EntityRelationship> = {
  encode(message: EntityRelationship, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.relationshipId !== "") {
      writer.uint32(10).string(message.relationshipId);
    }
    if (message.timeSlice !== undefined) {
      TimeSlice.encode(message.timeSlice, writer.uint32(18).fork()).join();
    }
    if (message.fromEntity !== undefined) {
      Entity.encode(message.fromEntity, writer.uint32(26).fork()).join();
    }
    if (message.toEntity !== undefined) {
      Entity.encode(message.toEntity, writer.uint32(34).fork()).join();
    }
    if (message.sourceText !== "") {
      writer.uint32(42).string(message.sourceText);
    }
    if (message.sourceChunkId !== "") {
      writer.uint32(50).string(message.sourceChunkId);
    }
    if (message.valueChange !== undefined) {
      ValueChange.encode(message.valueChange, writer.uint32(58).fork()).join();
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(66).fork()).join();
    }
    if (message.trade !== undefined) {
      Trade.encode(message.trade, writer.uint32(74).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): EntityRelationship {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityRelationship();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.relationshipId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeSlice = TimeSlice.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fromEntity = Entity.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.toEntity = Entity.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sourceText = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sourceChunkId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.valueChange = ValueChange.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.trade = Trade.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityRelationship {
    return {
      relationshipId: isSet(object.relationshipId) ? globalThis.String(object.relationshipId) : "",
      timeSlice: isSet(object.timeSlice) ? TimeSlice.fromJSON(object.timeSlice) : undefined,
      fromEntity: isSet(object.fromEntity) ? Entity.fromJSON(object.fromEntity) : undefined,
      toEntity: isSet(object.toEntity) ? Entity.fromJSON(object.toEntity) : undefined,
      sourceText: isSet(object.sourceText) ? globalThis.String(object.sourceText) : "",
      sourceChunkId: isSet(object.sourceChunkId) ? globalThis.String(object.sourceChunkId) : "",
      valueChange: isSet(object.valueChange) ? ValueChange.fromJSON(object.valueChange) : undefined,
      value: isSet(object.value) ? Value.fromJSON(object.value) : undefined,
      trade: isSet(object.trade) ? Trade.fromJSON(object.trade) : undefined,
    };
  },

  toJSON(message: EntityRelationship): unknown {
    const obj: any = {};
    if (message.relationshipId !== "") {
      obj.relationshipId = message.relationshipId;
    }
    if (message.timeSlice !== undefined) {
      obj.timeSlice = TimeSlice.toJSON(message.timeSlice);
    }
    if (message.fromEntity !== undefined) {
      obj.fromEntity = Entity.toJSON(message.fromEntity);
    }
    if (message.toEntity !== undefined) {
      obj.toEntity = Entity.toJSON(message.toEntity);
    }
    if (message.sourceText !== "") {
      obj.sourceText = message.sourceText;
    }
    if (message.sourceChunkId !== "") {
      obj.sourceChunkId = message.sourceChunkId;
    }
    if (message.valueChange !== undefined) {
      obj.valueChange = ValueChange.toJSON(message.valueChange);
    }
    if (message.value !== undefined) {
      obj.value = Value.toJSON(message.value);
    }
    if (message.trade !== undefined) {
      obj.trade = Trade.toJSON(message.trade);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityRelationship>, I>>(base?: I): EntityRelationship {
    return EntityRelationship.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityRelationship>, I>>(object: I): EntityRelationship {
    const message = createBaseEntityRelationship();
    message.relationshipId = object.relationshipId ?? "";
    message.timeSlice = (object.timeSlice !== undefined && object.timeSlice !== null)
      ? TimeSlice.fromPartial(object.timeSlice)
      : undefined;
    message.fromEntity = (object.fromEntity !== undefined && object.fromEntity !== null)
      ? Entity.fromPartial(object.fromEntity)
      : undefined;
    message.toEntity = (object.toEntity !== undefined && object.toEntity !== null)
      ? Entity.fromPartial(object.toEntity)
      : undefined;
    message.sourceText = object.sourceText ?? "";
    message.sourceChunkId = object.sourceChunkId ?? "";
    message.valueChange = (object.valueChange !== undefined && object.valueChange !== null)
      ? ValueChange.fromPartial(object.valueChange)
      : undefined;
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    message.trade = (object.trade !== undefined && object.trade !== null) ? Trade.fromPartial(object.trade) : undefined;
    return message;
  },
};

function createBaseMetaEntityRelationship(): MetaEntityRelationship {
  return {
    metaRelationshipId: "",
    fromEntityRelationship: undefined,
    toEntityRelationship: undefined,
    explanationText: "",
    causalityRelationship: undefined,
    correlationRelationship: undefined,
  };
}

export const MetaEntityRelationship: MessageFns<MetaEntityRelationship> = {
  encode(message: MetaEntityRelationship, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.metaRelationshipId !== "") {
      writer.uint32(10).string(message.metaRelationshipId);
    }
    if (message.fromEntityRelationship !== undefined) {
      EntityRelationship.encode(message.fromEntityRelationship, writer.uint32(18).fork()).join();
    }
    if (message.toEntityRelationship !== undefined) {
      EntityRelationship.encode(message.toEntityRelationship, writer.uint32(26).fork()).join();
    }
    if (message.explanationText !== "") {
      writer.uint32(34).string(message.explanationText);
    }
    if (message.causalityRelationship !== undefined) {
      CausalityRelationship.encode(message.causalityRelationship, writer.uint32(42).fork()).join();
    }
    if (message.correlationRelationship !== undefined) {
      CorrelationRelationship.encode(message.correlationRelationship, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MetaEntityRelationship {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetaEntityRelationship();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metaRelationshipId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fromEntityRelationship = EntityRelationship.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toEntityRelationship = EntityRelationship.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.explanationText = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.causalityRelationship = CausalityRelationship.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.correlationRelationship = CorrelationRelationship.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MetaEntityRelationship {
    return {
      metaRelationshipId: isSet(object.metaRelationshipId) ? globalThis.String(object.metaRelationshipId) : "",
      fromEntityRelationship: isSet(object.fromEntityRelationship)
        ? EntityRelationship.fromJSON(object.fromEntityRelationship)
        : undefined,
      toEntityRelationship: isSet(object.toEntityRelationship)
        ? EntityRelationship.fromJSON(object.toEntityRelationship)
        : undefined,
      explanationText: isSet(object.explanationText) ? globalThis.String(object.explanationText) : "",
      causalityRelationship: isSet(object.causalityRelationship)
        ? CausalityRelationship.fromJSON(object.causalityRelationship)
        : undefined,
      correlationRelationship: isSet(object.correlationRelationship)
        ? CorrelationRelationship.fromJSON(object.correlationRelationship)
        : undefined,
    };
  },

  toJSON(message: MetaEntityRelationship): unknown {
    const obj: any = {};
    if (message.metaRelationshipId !== "") {
      obj.metaRelationshipId = message.metaRelationshipId;
    }
    if (message.fromEntityRelationship !== undefined) {
      obj.fromEntityRelationship = EntityRelationship.toJSON(message.fromEntityRelationship);
    }
    if (message.toEntityRelationship !== undefined) {
      obj.toEntityRelationship = EntityRelationship.toJSON(message.toEntityRelationship);
    }
    if (message.explanationText !== "") {
      obj.explanationText = message.explanationText;
    }
    if (message.causalityRelationship !== undefined) {
      obj.causalityRelationship = CausalityRelationship.toJSON(message.causalityRelationship);
    }
    if (message.correlationRelationship !== undefined) {
      obj.correlationRelationship = CorrelationRelationship.toJSON(message.correlationRelationship);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MetaEntityRelationship>, I>>(base?: I): MetaEntityRelationship {
    return MetaEntityRelationship.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MetaEntityRelationship>, I>>(object: I): MetaEntityRelationship {
    const message = createBaseMetaEntityRelationship();
    message.metaRelationshipId = object.metaRelationshipId ?? "";
    message.fromEntityRelationship =
      (object.fromEntityRelationship !== undefined && object.fromEntityRelationship !== null)
        ? EntityRelationship.fromPartial(object.fromEntityRelationship)
        : undefined;
    message.toEntityRelationship = (object.toEntityRelationship !== undefined && object.toEntityRelationship !== null)
      ? EntityRelationship.fromPartial(object.toEntityRelationship)
      : undefined;
    message.explanationText = object.explanationText ?? "";
    message.causalityRelationship =
      (object.causalityRelationship !== undefined && object.causalityRelationship !== null)
        ? CausalityRelationship.fromPartial(object.causalityRelationship)
        : undefined;
    message.correlationRelationship =
      (object.correlationRelationship !== undefined && object.correlationRelationship !== null)
        ? CorrelationRelationship.fromPartial(object.correlationRelationship)
        : undefined;
    return message;
  },
};

function createBaseCausalityRelationship(): CausalityRelationship {
  return {};
}

export const CausalityRelationship: MessageFns<CausalityRelationship> = {
  encode(_: CausalityRelationship, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CausalityRelationship {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCausalityRelationship();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CausalityRelationship {
    return {};
  },

  toJSON(_: CausalityRelationship): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CausalityRelationship>, I>>(base?: I): CausalityRelationship {
    return CausalityRelationship.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CausalityRelationship>, I>>(_: I): CausalityRelationship {
    const message = createBaseCausalityRelationship();
    return message;
  },
};

function createBaseCorrelationRelationship(): CorrelationRelationship {
  return {};
}

export const CorrelationRelationship: MessageFns<CorrelationRelationship> = {
  encode(_: CorrelationRelationship, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CorrelationRelationship {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorrelationRelationship();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CorrelationRelationship {
    return {};
  },

  toJSON(_: CorrelationRelationship): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CorrelationRelationship>, I>>(base?: I): CorrelationRelationship {
    return CorrelationRelationship.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorrelationRelationship>, I>>(_: I): CorrelationRelationship {
    const message = createBaseCorrelationRelationship();
    return message;
  },
};

function createBaseTimeSlice(): TimeSlice {
  return { startTimestamp: undefined, endTimestamp: undefined, asOfTimestamp: undefined };
}

export const TimeSlice: MessageFns<TimeSlice> = {
  encode(message: TimeSlice, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.startTimestamp), writer.uint32(10).fork()).join();
    }
    if (message.endTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.endTimestamp), writer.uint32(18).fork()).join();
    }
    if (message.asOfTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.asOfTimestamp), writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TimeSlice {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeSlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.asOfTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeSlice {
    return {
      startTimestamp: isSet(object.startTimestamp) ? fromJsonTimestamp(object.startTimestamp) : undefined,
      endTimestamp: isSet(object.endTimestamp) ? fromJsonTimestamp(object.endTimestamp) : undefined,
      asOfTimestamp: isSet(object.asOfTimestamp) ? fromJsonTimestamp(object.asOfTimestamp) : undefined,
    };
  },

  toJSON(message: TimeSlice): unknown {
    const obj: any = {};
    if (message.startTimestamp !== undefined) {
      obj.startTimestamp = message.startTimestamp.toISOString();
    }
    if (message.endTimestamp !== undefined) {
      obj.endTimestamp = message.endTimestamp.toISOString();
    }
    if (message.asOfTimestamp !== undefined) {
      obj.asOfTimestamp = message.asOfTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeSlice>, I>>(base?: I): TimeSlice {
    return TimeSlice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TimeSlice>, I>>(object: I): TimeSlice {
    const message = createBaseTimeSlice();
    message.startTimestamp = object.startTimestamp ?? undefined;
    message.endTimestamp = object.endTimestamp ?? undefined;
    message.asOfTimestamp = object.asOfTimestamp ?? undefined;
    return message;
  },
};

function createBaseEntity(): Entity {
  return { entityName: "", entityType: 0, entitySubcomponents: [] };
}

export const Entity: MessageFns<Entity> = {
  encode(message: Entity, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.entityName !== "") {
      writer.uint32(10).string(message.entityName);
    }
    if (message.entityType !== 0) {
      writer.uint32(16).int32(message.entityType);
    }
    for (const v of message.entitySubcomponents) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Entity {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entityName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.entityType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entitySubcomponents.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Entity {
    return {
      entityName: isSet(object.entityName) ? globalThis.String(object.entityName) : "",
      entityType: isSet(object.entityType) ? entity_EntityTypeFromJSON(object.entityType) : 0,
      entitySubcomponents: globalThis.Array.isArray(object?.entitySubcomponents)
        ? object.entitySubcomponents.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Entity): unknown {
    const obj: any = {};
    if (message.entityName !== "") {
      obj.entityName = message.entityName;
    }
    if (message.entityType !== 0) {
      obj.entityType = entity_EntityTypeToJSON(message.entityType);
    }
    if (message.entitySubcomponents?.length) {
      obj.entitySubcomponents = message.entitySubcomponents;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Entity>, I>>(base?: I): Entity {
    return Entity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Entity>, I>>(object: I): Entity {
    const message = createBaseEntity();
    message.entityName = object.entityName ?? "";
    message.entityType = object.entityType ?? 0;
    message.entitySubcomponents = object.entitySubcomponents?.map((e) => e) || [];
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
