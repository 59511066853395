import { ChatRequest } from "../generated/protos/api";
import { ChatMessage } from "../generated/protos/chat";

export default async function chatMessages(baseUrl: string, body: ChatRequest) {
  const response = await fetch(`${baseUrl}/chat-messages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const rawData = await response.json();
  return ChatMessage.fromJSON(JSON.parse(rawData));
}
