import { useRef, useEffect } from "react";
import Chart, { ChartDataset } from "chart.js/auto";
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot";

Chart.register(BoxPlotController, BoxAndWiskers);

type BoxPlotProps= {
	datasets: ChartDataset[]
};
 
const BoxPlot: React.FC<BoxPlotProps> = ({ datasets }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
	// Get the canvas context, returning if it isn't ready
    const ctx = canvasRef.current?.getContext("2d");
	if (!ctx) return;

	// Destroy the existing chart if it exists
    if (chartRef.current) chartRef.current.destroy();

	// Render the chart onto the DOM
	chartRef.current = new Chart(ctx, {
		type: "boxplot",
		data: {
			labels: [''],
			datasets
		},
		options: {
			indexAxis: 'y',
			scales: {
				y: {
					display: false
				}
			},
			aspectRatio: 6,
			responsive: true,
			maintainAspectRatio: true,
			layout: {
				padding: 40
			},
			plugins: {
				title: {
					display: true,
					text: 'Sample Error',
					position: 'top',
					align: 'start'
				},
				legend: {
					display: false,
				}
			}
		},
	});

	// Cleanup function to destroy the chart instance
    return () => {
		if (chartRef.current) {
			chartRef.current.destroy();
			chartRef.current = null;
		}
	};
  }, [datasets]);

  return <canvas ref={canvasRef} />;
};

export default BoxPlot;
