import React from "react";
import { Send, X } from "lucide-react";
import { IconButton, Paper, TextField } from "@mui/material";
import { COLORS } from "../../styles/colors";

interface ChatInputProps {
  userInput: string;
  isLoading: boolean;
  onSubmit: () => void;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  onClear: () => void;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  userInput,
  isLoading,
  onSubmit,
  setUserInput,
  onClear,
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLoading && userInput.trim()) {
      onSubmit();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const showClearButton = !isLoading;
  const canSubmit = !isLoading && userInput.trim().length > 0;

  return (
    <form onSubmit={handleSubmit}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "12px 16px",
          borderRadius: "16px",
          backgroundColor: COLORS.white,
          boxShadow: COLORS.shadow.sm,
          border: `1px solid ${COLORS.grey[200]}`,
          position: "relative",
          "&:focus-within": {
            borderColor: COLORS.primary.main,
            boxShadow: COLORS.shadow.lg,
            transform: "translateY(-1px)",
          },
        }}
      >
        {showClearButton && (
          <IconButton
            onClick={onClear}
            size="small"
            aria-label="Clear Input"
            sx={{
              marginRight: 1,
              width: 32,
              height: 32,
              padding: 0,
              borderRadius: "50%",
              backgroundColor: "transparent",
              color: COLORS.grey[400],
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: COLORS.grey[100],
                color: COLORS.grey[600],
              },
            }}
          >
            <X size={18} />
          </IconButton>
        )}
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={userInput}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              handleSubmit(e);
            }
          }}
          placeholder="Enter a question here"
          disabled={isLoading}
          variant="outlined"
          slotProps={{
            input: {
              sx: {
                backgroundColor: "transparent",
                fontSize: "0.95rem",
                padding: "12px 14px",
                paddingRight: "40px",
                "& fieldset": { border: "none" },
                "&::placeholder": {
                  color: COLORS.grey[400],
                  opacity: 1,
                },
              },
            },
          }}
        />
        <IconButton
          type="submit"
          disabled={!canSubmit}
          size="small"
          aria-label="Send Message"
          sx={{
            marginLeft: 1,
            width: 32,
            height: 32,
            padding: 0,
            borderRadius: "8px",
            backgroundColor: canSubmit ? COLORS.primary.main : COLORS.grey[100],
            color: canSubmit ? COLORS.white : COLORS.grey[400],
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: canSubmit
                ? COLORS.primary.hover
                : COLORS.grey[100],
              transform: canSubmit ? "scale(1.05)" : "none",
              boxShadow: canSubmit ? COLORS.shadow.lg : "none",
            },
          }}
        >
          <Send size={18} />
        </IconButton>
      </Paper>
    </form>
  );
};
