export default function GoldmanSachs({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M25.8318 14.8561C28.308 16.5787 29.2231 18.059 29.25 20.1584C29.3038 23.2536 27.2044 25.7836 22.5212 25.7836H10.7862C5.43012 25.7836 3.25 22.6077 3.25 16.2826C3.25 9.63457 6.12992 6.21635 11.4053 6.21635H15.7655V11.5455H14.8235C13.8007 8.34265 12.7779 7.10455 11.19 7.10455C8.79451 7.10455 7.8794 9.87681 7.8794 16.4441C7.8794 23.0114 8.65994 24.8147 10.9477 24.8147C12.7779 24.8147 13.4508 23.6573 13.4508 21.0735V19.0818C13.4508 17.3323 12.8587 16.6863 10.9477 16.4172V15.5021H17.1651V17.0631C17.1651 22.4193 18.9684 24.8416 22.2521 24.8416C24.2976 24.8416 25.6972 23.7919 25.6434 21.854C25.6165 20.6159 24.9974 19.6201 23.1134 18.2743L20.4218 16.3634C18.2686 14.8561 17.4343 13.295 17.4343 11.1957C17.4343 8.34265 19.426 6.21635 23.4363 6.21635H28.4425V11.5455H27.5005C26.4239 7.93892 24.8898 7.15838 23.3018 7.15838C21.6061 7.15838 20.6102 8.01967 20.6102 9.52691C20.6102 10.7112 21.1755 11.5725 22.8442 12.7567L25.8318 14.8561Z" />
    </svg>
  );
}
