import React from "react";
import { Avatar, Box } from "@mui/material";
import { Bot } from "lucide-react";
import { COLORS } from "../../styles/colors";

const TypingIndicator: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      gap: "4px",
      height: "24px",
      "& span": {
        width: "5px",
        height: "5px",
        backgroundColor: COLORS.primary.main,
        borderRadius: "50%",
        animation: "pulse 1.5s infinite ease-in-out",
      },
      "& span:nth-of-type(1)": { animationDelay: "0s" },
      "& span:nth-of-type(2)": { animationDelay: "0.2s" },
      "& span:nth-of-type(3)": { animationDelay: "0.4s" },
      "@keyframes pulse": {
        "0%, 100%": { transform: "scale(1)", opacity: 0.3 },
        "50%": { transform: "scale(1.5)", opacity: 1 },
      },
    }}
  >
    <span></span>
    <span></span>
    <span></span>
  </Box>
);

export const LoadingIndicator: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        width: "100%",
        margin: "0 auto",
        padding: 2,
        borderRadius: "16px",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Avatar
        sx={{
          bgcolor: COLORS.primary.light,
          width: 36,
          height: 36,
          color: COLORS.primary.main,
        }}
      >
        <Bot size={18} />
      </Avatar>
      <Box
        sx={{
          flex: 1,
          fontSize: "1rem",
          lineHeight: 1.6,
          color: COLORS.grey[700],
          padding: 2,
          backgroundColor: COLORS.white,
          borderRadius: "16px",
          boxShadow: COLORS.shadow.sm,
          border: `1px solid ${COLORS.grey[200]}`,
          overflowWrap: "break-word",
          wordBreak: "break-word",
          "&:hover": {
            boxShadow: COLORS.shadow.md,
            borderColor: COLORS.grey[300],
          },
        }}
      >
        <TypingIndicator />
      </Box>
    </Box>
  );
};
