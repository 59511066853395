import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";

type AccuracyTableProps = {
  accuracies: {
    name: string;
    accuracy: number;
  }[];
};

const AccuracyTable: React.FC<AccuracyTableProps> = ({ accuracies }) => {
  // accuracies is actually absolute errors
  const sortedAccuracies = accuracies.sort((a, b) => a.accuracy - b.accuracy);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Bank</TableCell>
          <TableCell>Absolute Error</TableCell>
          <TableCell align="right">Rank</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedAccuracies.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{(row.accuracy * 100).toFixed(2)}</TableCell>
            <TableCell align="right">{index + 1}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AccuracyTable;
