export default function MorganStanley({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      baseProfile="tiny-ps"
      viewBox="0 0 1805 1102"
      height={height}
      width={width}
      fill="currentColor"
    >
      <g id="Layer">
        <path
          id="Layer"
          fillRule="evenodd"
          d="M1804.37 685.71C1804.37 801.7 1772.35 906.26 1714.34 980.53C1652.06 1060.19 1563.34 1102.22 1457.59 1102.22C1351.9 1102.22 1263.12 1060.26 1200.72 980.53C1142.7 906.32 1110.88 801.7 1110.88 685.71C1110.88 569.73 1142.7 464.98 1200.72 390.83C1263.12 311.36 1351.9 269.2 1457.59 269.2C1563.34 269.2 1652.06 311.36 1714.27 390.89C1772.29 464.98 1804.37 569.73 1804.37 685.71ZM1672.55 685.78C1672.55 487.24 1592.35 368.69 1457.59 368.69C1322.83 368.69 1242.38 487.24 1242.38 685.78C1242.38 884.24 1322.89 1002.86 1457.59 1002.86C1592.29 1002.86 1672.48 884.24 1672.55 685.78Z"
        />
        <path
          id="Layer"
          d="M474.34 874.96L208.57 0.48L0 0.48L0 1089.49L125.49 1089.49L125.49 93.5L128.19 97.21L426.28 1089.49L523.77 1089.49L821.86 97.21L824.62 93.5L824.62 1089.49L950.11 1089.49L950.11 0.48L741.23 0.48L475.78 874.96L475.02 879.54L474.34 874.96Z"
        />
      </g>
    </svg>
  );
}
