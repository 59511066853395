import { useRef, useEffect } from "react";
import Chart, { ChartDataset, registerables } from "chart.js/auto";
import ChartAnnotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-luxon';

Chart.register(...registerables, ChartAnnotationPlugin);

type LineChartProps = {
	title: string,
	datasets: ChartDataset[],
	min?: number,
	max?: number,
	currentMonth: number | false
};
  
const LineChart: React.FC<LineChartProps> = ({ title, datasets, min, max, currentMonth }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
	// Get the canvas context, returning if it isn't ready
    const ctx = canvasRef.current?.getContext("2d");
	if (!ctx) return;

	// Destroy the existing chart if it exists
    if (chartRef.current) chartRef.current.destroy();

	// Render the chart onto the DOM
	chartRef.current = new Chart(ctx, {
		type: "line",
		data: {
			datasets
		},
		options: {
			responsive: true,
			scales: {
				x: {
					type: 'time',
					time: {
						unit: 'month',
					},
					min,
					max,
					adapters: {
						date: {
							zone: 'UTC'
						}
					}
				}
			},
			plugins: {
				title: {
					display: true,
					text: title,
					position: 'top',
					align: 'start'
				},
				legend: {
					position: "top",
				},
				tooltip: {
					callbacks: {
						title: (context) => new Date((context[0].raw as { x: number }).x).toLocaleString("en-US", { timeZone: 'UTC', year: "numeric", month: "long" }),
					},
				},
				annotation: {
					annotations: !!currentMonth ?
						{
							verticalLine: {
								type: 'line',
								xMin: () => currentMonth,
								xMax: () => currentMonth,
								borderColor: 'black',
								borderWidth: 2,
								borderDash: [5, 5],
								label: {
									display: true,
									content: 'Historic',
									position: 'end',
									backgroundColor: 'rgba(0,0,0,0.8)',
									color: 'white',
									padding: 5
								}
							}
						} :
						undefined
				}
			},
		},
		plugins: [ChartAnnotationPlugin]
	});

	// Cleanup function to destroy the chart instance
    return () => {
		if (chartRef.current) {
			chartRef.current.destroy();
			chartRef.current = null;
		}
	};
  }, [datasets, currentMonth]);

  return <canvas ref={canvasRef} />;
};

export default LineChart;
