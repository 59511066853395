export default function JPMorganChase({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="currentColor"
    >
      <path d="M11.453 31.964c-0.161 0-0.313-0.026-0.438-0.089-0.13-0.057-0.255-0.135-0.375-0.229-0.115-0.094-0.198-0.208-0.245-0.349s-0.083-0.297-0.104-0.458l0.036-7.896h20.859l-9.479 9.021h-10.292zM32 20.547c-0.021 0.167-0.057 0.313-0.104 0.443-0.047 0.125-0.13 0.245-0.25 0.349-0.115 0.109-0.24 0.188-0.37 0.25-0.125 0.057-0.276 0.089-0.438 0.089h-7.896l-0.036-20.901 9.057 9.516v10.255zM20.547 0.036c0.141 0 0.286 0.031 0.443 0.089 0.151 0.057 0.266 0.135 0.349 0.229s0.161 0.208 0.229 0.354c0.073 0.141 0.094 0.292 0.073 0.453l0.031 7.896h-20.932l9.552-9.021zM0.036 11.453c-0.026-0.161 0-0.307 0.068-0.438 0.073-0.13 0.156-0.25 0.25-0.354s0.208-0.188 0.349-0.245c0.146-0.063 0.286-0.089 0.427-0.089h7.927l-0.036 20.896-8.984-9.516-0.036-10.255z" />
    </svg>
  );
}
