import React from "react";
import { Chip, Stack } from "@mui/material";
import { COLORS } from "../../styles/colors";

interface RecommendationsProps {
  recommendations: string[];
  handleRecommendationClick: (recommendation: string) => void;
  isPending: boolean;
}

export const Recommendations: React.FC<RecommendationsProps> = ({
  recommendations,
  handleRecommendationClick,
  isPending,
}) => {
  const firstTwoRecommendations = recommendations.slice(0, 2);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mb: 2,
        flexWrap: "wrap",
        gap: 1,
        justifyContent: "center",
        "& > *": {
          flex: "1 1 calc(50% - 8px)",
          maxWidth: "calc(50% - 8px)",
          whiteSpace: "normal",
        },
      }}
    >
      {firstTwoRecommendations.map((rec) => (
        <Chip
          key={rec}
          label={rec}
          onClick={() => !isPending && handleRecommendationClick(rec)}
          clickable={!isPending}
          sx={{
            userSelect: "none",
            borderRadius: "10px",
            backgroundColor: "white",
            border: `1px solid ${COLORS.grey[200]}`,
            padding: "8px 12px",
            height: "auto",
            transition: "all 0.2s ease",
            cursor: isPending ? "not-allowed" : "pointer",
            opacity: isPending ? 0.5 : 1,
            "&:hover": {
              backgroundColor: COLORS.grey[50],
              borderColor: COLORS.grey[300],
              transform: isPending ? "none" : "translateY(-1px)",
              boxShadow: isPending ? "none" : COLORS.shadow.sm,
            },
            "& .MuiChip-label": {
              fontSize: "0.875rem",
              fontWeight: 500,
              textAlign: "center",
              whiteSpace: "normal",
            },
          }}
        />
      ))}
    </Stack>
  );
};
