import FileUploader from "./FileUploader";
import DocumentSelectorList from "./DocumentSelectorList";
import "../../styles/FileUploader.css";

export default function Sidebar({ onClose }: { onClose: () => void }) {
  return (
    <div>
      <div className="file-uploader-header">
        <h2
          style={{
            fontSize: "20px",
            fontWeight: 700,
            background: "linear-gradient(135deg, #2563eb, #4f46e5)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            letterSpacing: "-0.02em",
          }}
        >
          Select Reports
        </h2>
        <button onClick={onClose} className="close-button">
          X
        </button>
      </div>
      <FileUploader />
      <DocumentSelectorList />
    </div>
  );
}
