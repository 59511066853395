import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Bot,
  User,
  ThumbsUp,
  ThumbsDown,
  Copy,
  SquareArrowOutUpRight,
} from "lucide-react";
import { ChatMessage } from "../../generated/protos/chat";
import ReactMarkdown from "react-markdown";
import "../../styles/Markdown.css";
import CitationModal from "../CitationModal";
import KnowledgeGraph from "./KnowledgeGraph";
import { COLORS } from "../../styles/colors";

interface ChatMessageProps {
  message: ChatMessage;
}

const ChatMessageComponent: React.FC<ChatMessageProps> = ({ message }) => {
  const [activeView, setActiveView] = useState<"none" | "evidence" | "graph">(
    "none"
  );
  const [selectedEvidenceId, setSelectedEvidenceId] = useState<string | null>(
    null
  );

  const isBot = message.llmMessage !== undefined;

  const content = isBot
    ? (message.llmMessage?.response as string)
    : (message.userMessage?.content as string);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (err) {
      console.error("Failed to copy message:", err);
    }
  };

  const actionButtons = [
    {
      icon: <ThumbsUp size={14} className="icon" />,
      label: "Like",
      onClick: () => {},
    },
    {
      icon: <ThumbsDown size={14} className="icon" />,
      label: "Dislike",
      onClick: () => {},
    },
    {
      icon: <Copy size={14} className="icon" />,
      label: "Copy",
      onClick: handleCopy,
    },
  ];

  const handleViewToggle = (view: "evidence" | "graph") => {
    setActiveView((currentView) => (currentView === view ? "none" : view));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          maxWidth: "850px",
          margin: "0 auto",
          width: "100%",
          padding: isBot ? "8px 16px 0" : "16px",
          boxSizing: "border-box",
          "&:hover": { backgroundColor: `${COLORS.grey[50]}40` },
        }}
      >
        <Avatar
          sx={{
            width: 36,
            height: 36,
            backgroundColor: isBot ? COLORS.primary.light : COLORS.grey[100],
            color: isBot ? COLORS.primary.main : COLORS.grey[500],
          }}
        >
          {isBot ? <Bot size={18} /> : <User size={18} />}
        </Avatar>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              fontSize: "1rem",
              lineHeight: 1.6,
              color: COLORS.grey[700],
              padding: 2,
              paddingY: isBot ? 2 : 0,
              backgroundColor: COLORS.white,
              borderRadius: "16px",
              boxShadow: COLORS.shadow.sm,
              border: `1px solid ${COLORS.grey[200]}`,
              overflowWrap: "break-word",
              wordBreak: "break-word",
              "&:hover": {
                boxShadow: COLORS.shadow.md,
                borderColor: COLORS.grey[300],
              },
            }}
          >
            <ReactMarkdown>{content}</ReactMarkdown>
            {isBot &&
              message.llmMessage &&
              message.llmMessage?.supportPoints?.length > 0 && (
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleViewToggle("evidence")}
                    sx={{ textTransform: "none" }}
                  >
                    {activeView === "evidence"
                      ? "Hide Supporting Evidence"
                      : "View Supporting Evidence"}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleViewToggle("graph")}
                    sx={{ textTransform: "none" }}
                  >
                    {activeView === "graph"
                      ? "Hide Knowledge Graph"
                      : "View Knowledge Graph"}
                  </Button>
                </Box>
              )}
            {activeView === "evidence" && (
              <Box sx={{ mt: 2 }}>
                {message.llmMessage?.supportPoints.map((point, index) => (
                  <Box
                    key={index}
                    onClick={() => setSelectedEvidenceId(point.evidenceIds[0])}
                    sx={{
                      cursor: "pointer",
                      "&:not(:last-child)": {
                        mb: 1,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mr: 1,
                          flexShrink: 0,
                        }}
                      >
                        •
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "inline",
                          "& .icon-wrapper": {
                            display: "inline-flex",
                            alignItems: "center",
                            ml: 1,
                            verticalAlign: "middle",
                            position: "relative",
                            top: "-0.1em",
                          },
                        }}
                      >
                        {point.text}
                        <span className="icon-wrapper">
                          <SquareArrowOutUpRight size={12} />
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            {activeView === "graph" && (
              <Box sx={{ mt: 2 }}>
                <KnowledgeGraph
                  relationshipIds={message.llmMessage?.relationshipIds || []}
                />
              </Box>
            )}
          </Box>
          {isBot && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                mt: 1,
                opacity: 0,
                transform: "translateY(4px)",
                transition: "all 0.2s ease",
                "&:hover": {
                  opacity: 1,
                  transform: "translateY(0)",
                },
              }}
            >
              {actionButtons.map((button) => (
                <Tooltip key={button.label} title={button.label}>
                  <IconButton
                    onClick={button.onClick}
                    sx={{
                      color: COLORS.grey[500],
                      "&:hover": {
                        backgroundColor: COLORS.grey[100],
                        color: COLORS.grey[700],
                      },
                    }}
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Stack>
          )}
        </Box>
      </Box>
      {selectedEvidenceId && (
        <CitationModal
          chunkId={selectedEvidenceId}
          open={!!selectedEvidenceId}
          onClose={() => setSelectedEvidenceId(null)}
        />
      )}
    </>
  );
};

export default ChatMessageComponent;
