import { useContext } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { BaseUrlContext } from "../Contexts";
import { useDocumentContext } from "../context/DocumentContext";
import { DocumentMetadata } from "../generated/protos/document";
import {
  TimeSeriesPlotDashboardRequest,
  TimeSeriesPlotDashboardResponse,
  ForecastDistributionRequest,
  ForecastDistributionResponseD,
} from "../generated/protos/api";

const analyticsKeys = {
  timeseries: (documents: DocumentMetadata[]) =>
    ["analytics", "timeseries", documents.map((d) => d.documentId)] as const,
  forecast: (documents: DocumentMetadata[]) =>
    ["analytics", "forecast", documents.map((d) => d.documentId)] as const,
};

const createTimeSeriesRequest = (
  selectedDocuments: DocumentMetadata[]
): TimeSeriesPlotDashboardRequest => ({
  selectedDocumentMetadatas: selectedDocuments,
});

const createForecastRequest = (
  selectedDocuments: DocumentMetadata[]
): ForecastDistributionRequest => ({
  selectedDocumentMetadatas: selectedDocuments,
});

async function fetchTimeseriesAnalytics(
  baseUrl: string,
  selectedDocuments: DocumentMetadata[],
  signal?: AbortSignal
): Promise<TimeSeriesPlotDashboardResponse> {
  const response = await fetch(`${baseUrl}/analytics/timeseries`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(createTimeSeriesRequest(selectedDocuments)),
    signal,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch timeseries analytics");
  }

  const rawData = await response.json();
  return TimeSeriesPlotDashboardResponse.fromJSON(JSON.parse(rawData));
}

async function fetchForecastAnalytics(
  baseUrl: string,
  selectedDocuments: DocumentMetadata[],
  signal?: AbortSignal
): Promise<ForecastDistributionResponseD> {
  const response = await fetch(`${baseUrl}/analytics/forecast`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(createForecastRequest(selectedDocuments)),
    signal,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch forecast analytics");
  }

  const rawData = await response.json();
  return ForecastDistributionResponseD.fromJSON(JSON.parse(rawData));
}

export function useTimeseriesAnalytics() {
  const baseUrl = useContext(BaseUrlContext);
  const { selectedDocuments } = useDocumentContext();

  return useQuery({
    queryKey: analyticsKeys.timeseries(selectedDocuments),
    queryFn: ({ signal }) =>
      fetchTimeseriesAnalytics(baseUrl, selectedDocuments, signal),
    enabled: selectedDocuments.length > 0,
    staleTime: 5 * 60 * 1000,
  });
}

export function useForecastAnalytics() {
  const baseUrl = useContext(BaseUrlContext);
  const { selectedDocuments } = useDocumentContext();

  return useQuery({
    queryKey: analyticsKeys.forecast(selectedDocuments),
    queryFn: ({ signal }) =>
      fetchForecastAnalytics(baseUrl, selectedDocuments, signal),
    enabled: selectedDocuments.length > 0,
    staleTime: 5 * 60 * 1000,
  });
}
export function useAnalyticsData() {
  const baseUrl = useContext(BaseUrlContext);
  const { selectedDocuments } = useDocumentContext();

  const queries = useQueries({
    queries: [
      {
        queryKey: analyticsKeys.timeseries(selectedDocuments),
        queryFn: ({ signal }) =>
          fetchTimeseriesAnalytics(baseUrl, selectedDocuments, signal),
        enabled: selectedDocuments.length > 0,
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: analyticsKeys.forecast(selectedDocuments),
        queryFn: ({ signal }) =>
          fetchForecastAnalytics(baseUrl, selectedDocuments, signal),
        enabled: selectedDocuments.length > 0,
        staleTime: 5 * 60 * 1000,
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      },
    ],
  });

  const [timeseriesResult, forecastResult] = queries;

  return {
    timeseriesData: timeseriesResult.data ?? null,
    forecastData: forecastResult.data ?? null,
    isTimeseriesLoading: timeseriesResult.isLoading,
    isForecastLoading: forecastResult.isLoading,
    error: timeseriesResult.error || forecastResult.error,
  };
}
