import { ReactNode, createContext, useContext, useState } from "react";
import {
  DocumentExtraction,
  DocumentMetadata,
} from "../generated/protos/document";

interface DocumentContextType {
  allDocuments: DocumentExtraction[];
  setAllDocuments: React.Dispatch<React.SetStateAction<DocumentExtraction[]>>;
  selectedDocuments: DocumentMetadata[];
  setSelectedDocuments: React.Dispatch<
    React.SetStateAction<DocumentMetadata[]>
  >;
  queryEnabled: boolean;
  setQueryEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DocumentContext = createContext<DocumentContextType | undefined>(
  undefined
);

export const DocumentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [allDocuments, setAllDocuments] = useState<DocumentExtraction[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentMetadata[]
  >([]);
  const [queryEnabled, setQueryEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <DocumentContext.Provider
      value={{
        allDocuments,
        setAllDocuments,
        selectedDocuments,
        setSelectedDocuments,
        queryEnabled,
        setQueryEnabled,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocumentContext = () => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error(
      "useDocumentContext must be used within a DocumentProvider"
    );
  }
  return context;
};
