import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import getCitation from "../api/citations";
import { useContext } from "react";
import { BaseUrlContext } from "../Contexts";
import { X } from "lucide-react";

interface CitationModalProps {
  chunkId?: string | null;
  open: boolean;
  onClose: () => void;
}

export default function CitationModal({
  chunkId,
  open,
  onClose,
}: CitationModalProps) {
  const baseUrl = useContext(BaseUrlContext);

  const { data, isLoading, error } = useQuery({
    queryKey: ["citations", chunkId],
    queryFn: ({ signal }) => {
      if (!chunkId) return undefined;
      return getCitation(baseUrl, chunkId, signal);
    },
    enabled: !!chunkId,
  });

  const pdfUrl = data
    ? URL.createObjectURL(
        new Blob([data.citationResponse.documentBytes], {
          type: "application/pdf",
        })
      )
    : null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="citation-modal"
      aria-describedby="citation-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: "8px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography id="citation-modal" variant="h6" component="h2">
            Document
          </Typography>
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </Box>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography color="error" variant="h6">
              Failed to load citation data
            </Typography>
          </Box>
        )}
        {data && pdfUrl && (
          <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <iframe
              src={`${pdfUrl}#page=${data.citationResponse.pageNumber}`}
              title="Citation PDF"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
}
