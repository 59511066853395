// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/api.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { BankAnalytics, EnsemblePredictionDistribution, HistoricalMetricData } from "./analytics";
import { ChatMessage } from "./chat";
import { DocumentExtraction, DocumentMetadata } from "./document";

export const protobufPackage = "finllm";

export interface DocumentExtractionRequest {
  existingDocumentMetadatas: DocumentMetadata[];
  userEmail: string;
}

export interface DocumentExtractionResponse {
  newDocumentExtractions: DocumentExtraction[];
}

export interface ChatRequest {
  selectedDocumentMetadatas: DocumentMetadata[];
  chatHistory: ChatMessage[];
}

export interface CitationResponse {
  pageNumber: number;
  documentBytes: Uint8Array;
}

export interface KeyThemesRequest {
  selectedDocumentExtractions: DocumentExtraction[];
  requestId: string;
}

export interface KeyTheme {
  themeDescription: string;
  supportingPoints: string[];
}

export interface KeyThemesResponse {
  keyThemes: KeyTheme[];
  requestId: string;
}

export interface TimeSeriesPlotDashboardRequest {
  selectedDocumentMetadatas: DocumentMetadata[];
}

export interface TimeSeriesPlotDashboardResponse {
  metricsHistoricalData: HistoricalMetricData[];
  allBanksAnalytics: BankAnalytics[];
}

export interface ForecastDistributionRequest {
  selectedDocumentMetadatas: DocumentMetadata[];
}

export interface ForecastDistributionResponseD {
  ensemblePredictionDistributions: EnsemblePredictionDistribution[];
}

function createBaseDocumentExtractionRequest(): DocumentExtractionRequest {
  return { existingDocumentMetadatas: [], userEmail: "" };
}

export const DocumentExtractionRequest: MessageFns<DocumentExtractionRequest> = {
  encode(message: DocumentExtractionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.existingDocumentMetadatas) {
      DocumentMetadata.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.userEmail !== "") {
      writer.uint32(18).string(message.userEmail);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DocumentExtractionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentExtractionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.existingDocumentMetadatas.push(DocumentMetadata.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentExtractionRequest {
    return {
      existingDocumentMetadatas: globalThis.Array.isArray(object?.existingDocumentMetadatas)
        ? object.existingDocumentMetadatas.map((e: any) => DocumentMetadata.fromJSON(e))
        : [],
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
    };
  },

  toJSON(message: DocumentExtractionRequest): unknown {
    const obj: any = {};
    if (message.existingDocumentMetadatas?.length) {
      obj.existingDocumentMetadatas = message.existingDocumentMetadatas.map((e) => DocumentMetadata.toJSON(e));
    }
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentExtractionRequest>, I>>(base?: I): DocumentExtractionRequest {
    return DocumentExtractionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DocumentExtractionRequest>, I>>(object: I): DocumentExtractionRequest {
    const message = createBaseDocumentExtractionRequest();
    message.existingDocumentMetadatas = object.existingDocumentMetadatas?.map((e) => DocumentMetadata.fromPartial(e)) ||
      [];
    message.userEmail = object.userEmail ?? "";
    return message;
  },
};

function createBaseDocumentExtractionResponse(): DocumentExtractionResponse {
  return { newDocumentExtractions: [] };
}

export const DocumentExtractionResponse: MessageFns<DocumentExtractionResponse> = {
  encode(message: DocumentExtractionResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.newDocumentExtractions) {
      DocumentExtraction.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DocumentExtractionResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentExtractionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newDocumentExtractions.push(DocumentExtraction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentExtractionResponse {
    return {
      newDocumentExtractions: globalThis.Array.isArray(object?.newDocumentExtractions)
        ? object.newDocumentExtractions.map((e: any) => DocumentExtraction.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DocumentExtractionResponse): unknown {
    const obj: any = {};
    if (message.newDocumentExtractions?.length) {
      obj.newDocumentExtractions = message.newDocumentExtractions.map((e) => DocumentExtraction.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentExtractionResponse>, I>>(base?: I): DocumentExtractionResponse {
    return DocumentExtractionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DocumentExtractionResponse>, I>>(object: I): DocumentExtractionResponse {
    const message = createBaseDocumentExtractionResponse();
    message.newDocumentExtractions = object.newDocumentExtractions?.map((e) => DocumentExtraction.fromPartial(e)) || [];
    return message;
  },
};

function createBaseChatRequest(): ChatRequest {
  return { selectedDocumentMetadatas: [], chatHistory: [] };
}

export const ChatRequest: MessageFns<ChatRequest> = {
  encode(message: ChatRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.selectedDocumentMetadatas) {
      DocumentMetadata.encode(v!, writer.uint32(10).fork()).join();
    }
    for (const v of message.chatHistory) {
      ChatMessage.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChatRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selectedDocumentMetadatas.push(DocumentMetadata.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.chatHistory.push(ChatMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatRequest {
    return {
      selectedDocumentMetadatas: globalThis.Array.isArray(object?.selectedDocumentMetadatas)
        ? object.selectedDocumentMetadatas.map((e: any) => DocumentMetadata.fromJSON(e))
        : [],
      chatHistory: globalThis.Array.isArray(object?.chatHistory)
        ? object.chatHistory.map((e: any) => ChatMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ChatRequest): unknown {
    const obj: any = {};
    if (message.selectedDocumentMetadatas?.length) {
      obj.selectedDocumentMetadatas = message.selectedDocumentMetadatas.map((e) => DocumentMetadata.toJSON(e));
    }
    if (message.chatHistory?.length) {
      obj.chatHistory = message.chatHistory.map((e) => ChatMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatRequest>, I>>(base?: I): ChatRequest {
    return ChatRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatRequest>, I>>(object: I): ChatRequest {
    const message = createBaseChatRequest();
    message.selectedDocumentMetadatas = object.selectedDocumentMetadatas?.map((e) => DocumentMetadata.fromPartial(e)) ||
      [];
    message.chatHistory = object.chatHistory?.map((e) => ChatMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCitationResponse(): CitationResponse {
  return { pageNumber: 0, documentBytes: new Uint8Array(0) };
}

export const CitationResponse: MessageFns<CitationResponse> = {
  encode(message: CitationResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageNumber !== 0) {
      writer.uint32(8).int32(message.pageNumber);
    }
    if (message.documentBytes.length !== 0) {
      writer.uint32(18).bytes(message.documentBytes);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CitationResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCitationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documentBytes = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CitationResponse {
    return {
      pageNumber: isSet(object.pageNumber) ? globalThis.Number(object.pageNumber) : 0,
      documentBytes: isSet(object.documentBytes) ? bytesFromBase64(object.documentBytes) : new Uint8Array(0),
    };
  },

  toJSON(message: CitationResponse): unknown {
    const obj: any = {};
    if (message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    if (message.documentBytes.length !== 0) {
      obj.documentBytes = base64FromBytes(message.documentBytes);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CitationResponse>, I>>(base?: I): CitationResponse {
    return CitationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CitationResponse>, I>>(object: I): CitationResponse {
    const message = createBaseCitationResponse();
    message.pageNumber = object.pageNumber ?? 0;
    message.documentBytes = object.documentBytes ?? new Uint8Array(0);
    return message;
  },
};

function createBaseKeyThemesRequest(): KeyThemesRequest {
  return { selectedDocumentExtractions: [], requestId: "" };
}

export const KeyThemesRequest: MessageFns<KeyThemesRequest> = {
  encode(message: KeyThemesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.selectedDocumentExtractions) {
      DocumentExtraction.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.requestId !== "") {
      writer.uint32(18).string(message.requestId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): KeyThemesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyThemesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selectedDocumentExtractions.push(DocumentExtraction.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyThemesRequest {
    return {
      selectedDocumentExtractions: globalThis.Array.isArray(object?.selectedDocumentExtractions)
        ? object.selectedDocumentExtractions.map((e: any) => DocumentExtraction.fromJSON(e))
        : [],
      requestId: isSet(object.requestId) ? globalThis.String(object.requestId) : "",
    };
  },

  toJSON(message: KeyThemesRequest): unknown {
    const obj: any = {};
    if (message.selectedDocumentExtractions?.length) {
      obj.selectedDocumentExtractions = message.selectedDocumentExtractions.map((e) => DocumentExtraction.toJSON(e));
    }
    if (message.requestId !== "") {
      obj.requestId = message.requestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyThemesRequest>, I>>(base?: I): KeyThemesRequest {
    return KeyThemesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyThemesRequest>, I>>(object: I): KeyThemesRequest {
    const message = createBaseKeyThemesRequest();
    message.selectedDocumentExtractions =
      object.selectedDocumentExtractions?.map((e) => DocumentExtraction.fromPartial(e)) || [];
    message.requestId = object.requestId ?? "";
    return message;
  },
};

function createBaseKeyTheme(): KeyTheme {
  return { themeDescription: "", supportingPoints: [] };
}

export const KeyTheme: MessageFns<KeyTheme> = {
  encode(message: KeyTheme, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.themeDescription !== "") {
      writer.uint32(10).string(message.themeDescription);
    }
    for (const v of message.supportingPoints) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): KeyTheme {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyTheme();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.themeDescription = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.supportingPoints.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyTheme {
    return {
      themeDescription: isSet(object.themeDescription) ? globalThis.String(object.themeDescription) : "",
      supportingPoints: globalThis.Array.isArray(object?.supportingPoints)
        ? object.supportingPoints.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: KeyTheme): unknown {
    const obj: any = {};
    if (message.themeDescription !== "") {
      obj.themeDescription = message.themeDescription;
    }
    if (message.supportingPoints?.length) {
      obj.supportingPoints = message.supportingPoints;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyTheme>, I>>(base?: I): KeyTheme {
    return KeyTheme.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyTheme>, I>>(object: I): KeyTheme {
    const message = createBaseKeyTheme();
    message.themeDescription = object.themeDescription ?? "";
    message.supportingPoints = object.supportingPoints?.map((e) => e) || [];
    return message;
  },
};

function createBaseKeyThemesResponse(): KeyThemesResponse {
  return { keyThemes: [], requestId: "" };
}

export const KeyThemesResponse: MessageFns<KeyThemesResponse> = {
  encode(message: KeyThemesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keyThemes) {
      KeyTheme.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.requestId !== "") {
      writer.uint32(18).string(message.requestId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): KeyThemesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyThemesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyThemes.push(KeyTheme.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyThemesResponse {
    return {
      keyThemes: globalThis.Array.isArray(object?.keyThemes)
        ? object.keyThemes.map((e: any) => KeyTheme.fromJSON(e))
        : [],
      requestId: isSet(object.requestId) ? globalThis.String(object.requestId) : "",
    };
  },

  toJSON(message: KeyThemesResponse): unknown {
    const obj: any = {};
    if (message.keyThemes?.length) {
      obj.keyThemes = message.keyThemes.map((e) => KeyTheme.toJSON(e));
    }
    if (message.requestId !== "") {
      obj.requestId = message.requestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyThemesResponse>, I>>(base?: I): KeyThemesResponse {
    return KeyThemesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyThemesResponse>, I>>(object: I): KeyThemesResponse {
    const message = createBaseKeyThemesResponse();
    message.keyThemes = object.keyThemes?.map((e) => KeyTheme.fromPartial(e)) || [];
    message.requestId = object.requestId ?? "";
    return message;
  },
};

function createBaseTimeSeriesPlotDashboardRequest(): TimeSeriesPlotDashboardRequest {
  return { selectedDocumentMetadatas: [] };
}

export const TimeSeriesPlotDashboardRequest: MessageFns<TimeSeriesPlotDashboardRequest> = {
  encode(message: TimeSeriesPlotDashboardRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.selectedDocumentMetadatas) {
      DocumentMetadata.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TimeSeriesPlotDashboardRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeSeriesPlotDashboardRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selectedDocumentMetadatas.push(DocumentMetadata.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeSeriesPlotDashboardRequest {
    return {
      selectedDocumentMetadatas: globalThis.Array.isArray(object?.selectedDocumentMetadatas)
        ? object.selectedDocumentMetadatas.map((e: any) => DocumentMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TimeSeriesPlotDashboardRequest): unknown {
    const obj: any = {};
    if (message.selectedDocumentMetadatas?.length) {
      obj.selectedDocumentMetadatas = message.selectedDocumentMetadatas.map((e) => DocumentMetadata.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeSeriesPlotDashboardRequest>, I>>(base?: I): TimeSeriesPlotDashboardRequest {
    return TimeSeriesPlotDashboardRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesPlotDashboardRequest>, I>>(
    object: I,
  ): TimeSeriesPlotDashboardRequest {
    const message = createBaseTimeSeriesPlotDashboardRequest();
    message.selectedDocumentMetadatas = object.selectedDocumentMetadatas?.map((e) => DocumentMetadata.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseTimeSeriesPlotDashboardResponse(): TimeSeriesPlotDashboardResponse {
  return { metricsHistoricalData: [], allBanksAnalytics: [] };
}

export const TimeSeriesPlotDashboardResponse: MessageFns<TimeSeriesPlotDashboardResponse> = {
  encode(message: TimeSeriesPlotDashboardResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.metricsHistoricalData) {
      HistoricalMetricData.encode(v!, writer.uint32(10).fork()).join();
    }
    for (const v of message.allBanksAnalytics) {
      BankAnalytics.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TimeSeriesPlotDashboardResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeSeriesPlotDashboardResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metricsHistoricalData.push(HistoricalMetricData.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.allBanksAnalytics.push(BankAnalytics.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeSeriesPlotDashboardResponse {
    return {
      metricsHistoricalData: globalThis.Array.isArray(object?.metricsHistoricalData)
        ? object.metricsHistoricalData.map((e: any) => HistoricalMetricData.fromJSON(e))
        : [],
      allBanksAnalytics: globalThis.Array.isArray(object?.allBanksAnalytics)
        ? object.allBanksAnalytics.map((e: any) => BankAnalytics.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TimeSeriesPlotDashboardResponse): unknown {
    const obj: any = {};
    if (message.metricsHistoricalData?.length) {
      obj.metricsHistoricalData = message.metricsHistoricalData.map((e) => HistoricalMetricData.toJSON(e));
    }
    if (message.allBanksAnalytics?.length) {
      obj.allBanksAnalytics = message.allBanksAnalytics.map((e) => BankAnalytics.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeSeriesPlotDashboardResponse>, I>>(base?: I): TimeSeriesPlotDashboardResponse {
    return TimeSeriesPlotDashboardResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesPlotDashboardResponse>, I>>(
    object: I,
  ): TimeSeriesPlotDashboardResponse {
    const message = createBaseTimeSeriesPlotDashboardResponse();
    message.metricsHistoricalData = object.metricsHistoricalData?.map((e) => HistoricalMetricData.fromPartial(e)) || [];
    message.allBanksAnalytics = object.allBanksAnalytics?.map((e) => BankAnalytics.fromPartial(e)) || [];
    return message;
  },
};

function createBaseForecastDistributionRequest(): ForecastDistributionRequest {
  return { selectedDocumentMetadatas: [] };
}

export const ForecastDistributionRequest: MessageFns<ForecastDistributionRequest> = {
  encode(message: ForecastDistributionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.selectedDocumentMetadatas) {
      DocumentMetadata.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ForecastDistributionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseForecastDistributionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selectedDocumentMetadatas.push(DocumentMetadata.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ForecastDistributionRequest {
    return {
      selectedDocumentMetadatas: globalThis.Array.isArray(object?.selectedDocumentMetadatas)
        ? object.selectedDocumentMetadatas.map((e: any) => DocumentMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ForecastDistributionRequest): unknown {
    const obj: any = {};
    if (message.selectedDocumentMetadatas?.length) {
      obj.selectedDocumentMetadatas = message.selectedDocumentMetadatas.map((e) => DocumentMetadata.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ForecastDistributionRequest>, I>>(base?: I): ForecastDistributionRequest {
    return ForecastDistributionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ForecastDistributionRequest>, I>>(object: I): ForecastDistributionRequest {
    const message = createBaseForecastDistributionRequest();
    message.selectedDocumentMetadatas = object.selectedDocumentMetadatas?.map((e) => DocumentMetadata.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseForecastDistributionResponseD(): ForecastDistributionResponseD {
  return { ensemblePredictionDistributions: [] };
}

export const ForecastDistributionResponseD: MessageFns<ForecastDistributionResponseD> = {
  encode(message: ForecastDistributionResponseD, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.ensemblePredictionDistributions) {
      EnsemblePredictionDistribution.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ForecastDistributionResponseD {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseForecastDistributionResponseD();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ensemblePredictionDistributions.push(EnsemblePredictionDistribution.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ForecastDistributionResponseD {
    return {
      ensemblePredictionDistributions: globalThis.Array.isArray(object?.ensemblePredictionDistributions)
        ? object.ensemblePredictionDistributions.map((e: any) => EnsemblePredictionDistribution.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ForecastDistributionResponseD): unknown {
    const obj: any = {};
    if (message.ensemblePredictionDistributions?.length) {
      obj.ensemblePredictionDistributions = message.ensemblePredictionDistributions.map((e) =>
        EnsemblePredictionDistribution.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ForecastDistributionResponseD>, I>>(base?: I): ForecastDistributionResponseD {
    return ForecastDistributionResponseD.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ForecastDistributionResponseD>, I>>(
    object: I,
  ): ForecastDistributionResponseD {
    const message = createBaseForecastDistributionResponseD();
    message.ensemblePredictionDistributions =
      object.ensemblePredictionDistributions?.map((e) => EnsemblePredictionDistribution.fromPartial(e)) || [];
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
