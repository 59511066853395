export default function HSBC({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 195.000000 195.000000"
      preserveAspectRatio="xMidYMid meet"
      height={height}
      width={width}
      fill="currentColor"
    >
      <g transform="translate(0.000000,195.000000) scale(0.100000,-0.100000)">
        <path d="M243 1218 l-243 -243 243 -242 243 -243 489 0 490 0 243 243 243 242 -244 243 -244 242 -488 0 -489 0 -243 -242z m515 -30 c114 -115 210 -208 213 -208 4 0 114 106 245 236 l239 235 3 -235 c1 -129 1 -343 0 -476 l-3 -242 -239 236 c-132 130 -242 236 -245 236 -3 0 -90 -84 -193 -187 -103 -104 -210 -210 -237 -238 l-51 -49 0 479 0 479 31 -30 c17 -16 124 -122 237 -236z" />
      </g>
    </svg>
  );
}
