import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

export const ChartsContainer = styled(Paper)(({ theme }) => ({
  padding: "24px",
  borderRadius: "16px",
  backgroundColor: "inherit",
  width: "100%",
  maxWidth: "100%",
  margin: "0 auto",
  boxShadow: "none",
  overflow: "auto",
  boxSizing: "border-box",
}));
