import {
  DocumentExtraction,
  DocumentMetadata,
  DocumentStatus,
} from "../../generated/protos/document";
import "../../styles/DocumentSelectorList.css";
import { useDocumentContext } from "../../context/DocumentContext";
import { useContext, useEffect, useState } from "react";
import { BaseUrlContext } from "../../Contexts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteDocument } from "../../api/document-extractions";
import { Trash, X } from "lucide-react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import PreviewModal from "./PreviewModal";

export default function DocumentSelectorList() {
  const { allDocuments } = useDocumentContext();

  const processingDocs = allDocuments.filter(
    (doc) =>
      doc.documentMetadata?.documentStatus ===
      DocumentStatus.DOCUMENT_STATUS_PROCESSING
  );
  const erroredDocs = allDocuments.filter(
    (doc) =>
      doc.documentMetadata?.documentStatus ===
      DocumentStatus.DOCUMENT_STATUS_ERROR
  );
  const completedDocs = allDocuments.filter(
    (doc) =>
      doc.documentMetadata?.documentStatus ===
      DocumentStatus.DOCUMENT_STATUS_COMPLETED
  );

  return (
    <div className="document-selector">
      <StatusSection
        title="Processing"
        docs={processingDocs}
        statusClass="processing"
        showCheckbox={false}
      />
      <StatusSection
        title="Completed"
        docs={completedDocs}
        statusClass="completed"
        showCheckbox={true}
      />
      <StatusSection
        title="Error during Processing"
        docs={erroredDocs}
        statusClass="error"
        showCheckbox={false}
      />
    </div>
  );
}

function StatusSection({
  title,
  docs,
  statusClass,
  showCheckbox,
}: {
  title: string;
  docs: DocumentExtraction[];
  statusClass: "processing" | "completed" | "error";
  showCheckbox: boolean;
}) {
  const {
    selectedDocuments,
    setAllDocuments,
    setSelectedDocuments,
    setQueryEnabled,
  } = useDocumentContext();
  const baseUrl = useContext(BaseUrlContext);
  const [tempSelectedDocs, setTempSelectedDocs] = useState<DocumentMetadata[]>(
    []
  );
  const [loadingDocIds, setLoadingDocIds] = useState<Set<string>>(new Set());
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentExtraction | null>(null);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (documentId: string) => deleteDocument(baseUrl, documentId),
    onMutate: (documentId) =>
      setLoadingDocIds((prev) => new Set(prev).add(documentId)),
    onSuccess: (_, documentId) => {
      queryClient.invalidateQueries({ queryKey: ["documentExtractions"] });

      setLoadingDocIds((prev) => {
        const updated = new Set(prev);
        updated.delete(documentId);
        return updated;
      });

      setAllDocuments((prevDocs) =>
        prevDocs.filter(
          (doc) => doc.documentMetadata?.documentId !== documentId
        )
      );

      setSelectedDocuments((prevSelectedDocs) =>
        prevSelectedDocs.filter((doc) => doc.documentId !== documentId)
      );
    },
    onError: (_, documentId) => {
      setLoadingDocIds((prev) => {
        const updated = new Set(prev);
        updated.delete(documentId);
        return updated;
      });
      alert(`Failed to delete document with ID due to server error: ${documentId}`);
    },
});

  useEffect(() => {
    if (showCheckbox) {
      setTempSelectedDocs(selectedDocuments);
    }
  }, [showCheckbox, selectedDocuments]);

  const handleToggle = (docMetadata: DocumentMetadata) => {
    setTempSelectedDocs((prev) =>
      prev.some((doc) => doc.documentId === docMetadata.documentId)
        ? prev.filter((doc) => doc.documentId !== docMetadata.documentId)
        : [...prev, docMetadata]
    );
  };

  const handleConfirmSelection = () => {
    setSelectedDocuments(tempSelectedDocs);
    queryClient.invalidateQueries({ queryKey: ["keyThemes"] });
    queryClient.invalidateQueries({ queryKey: ["analytics"] });
  };

  const handleOpenModal = (data: DocumentExtraction) => {
    setSelectedDocument(data);
    setModalOpen(true);
    setQueryEnabled(false);
  };

  const handleCloseModal = () => {
    setSelectedDocument(null);
    setModalOpen(false);
    setQueryEnabled(true);
  };

  if (docs.length === 0) return null;

  return (
    <>
      <div>
        <h3 className="section-title">{title}</h3>
        {showCheckbox && (
          <button className="confirm-button" onClick={handleConfirmSelection}>
            Confirm Selection
          </button>
        )}
        <div className="document-list">
          {docs.map((doc) => {
            if (!doc.documentMetadata) return null;

            const { documentId, documentName } = doc.documentMetadata;

            return (
              <div key={documentId} className={`document-card ${statusClass}`}>
                {showCheckbox && (
                  <input
                    type="checkbox"
                    className="document-checkbox"
                    checked={selectedDocuments.some(
                      (selected) => selected.documentId === documentId
                    )}
                    onChange={() =>
                      doc.documentMetadata && handleToggle(doc.documentMetadata)
                    }
                  />
                )}
                <span
                  className="document-name"
                  onClick={
                    statusClass === "completed"
                      ? () => handleOpenModal(doc)
                      : undefined
                  }
                  style={{
                    cursor: statusClass === "completed" ? "pointer" : "auto",
                  }}
                >
                  {documentName}
                </span>
                <div className={`status-indicator ${statusClass}`} />
                <div>
                  {statusClass === "processing" && (
                    <Tooltip
                      title={
                        loadingDocIds.has(documentId) ? "Canceling" : "Cancel"
                      }
                    >
                      <span>
                        <IconButton
                          aria-label="Cancel"
                          color="warning"
                          onClick={() => deleteMutation.mutate(documentId)}
                          disabled={loadingDocIds.has(documentId)}
                        >
                          {loadingDocIds.has(documentId) ? (
                            <CircularProgress size={20} />
                          ) : (
                            <X size={20} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {(statusClass === "completed" || statusClass === "error") && (
                    <Tooltip
                      title={
                        loadingDocIds.has(documentId) ? "Deleting" : "Delete"
                      }
                    >
                      <span>
                        <IconButton
                          aria-label="Delete"
                          color="error"
                          onClick={() => deleteMutation.mutate(documentId)}
                          disabled={loadingDocIds.has(documentId)}
                        >
                          {loadingDocIds.has(documentId) ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Trash size={20} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PreviewModal
        document={selectedDocument}
        open={modalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
}
