export default function CreditSuisse({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 1510 1060"
      height={height}
      width={width}
      fill="currentColor"
    >
      <title>Credit_Suisse_Logo-svg</title>

      <g id="layer1">
        <g id="g3803">
          <g id="g3801">
            <path
              id="path3795"
              d="m862.1 509.8c-40.4-214.3-190.2-416.5-283.3-509.6 360.2 238.7 724.5 606.7 930.8 946.4-84.9-48.5-161.8-80.8-238.7-105.1-125.5-113.3-263.1-226.5-408.8-331.7z"
            />
            <path
              id="path3797"
              d="m1489.4 1059.9c-416.8-141.6-736.5-97.1-947-36.4-97.1-444.9-331.8-861.5-542.3-1003 287.4 125.3 590.9 295.2 862 489.3 16.2 93.1 12.2 190.1-28.3 283.2 109.3-12.2 259-8.1 437.1 48.5q115.3 109.2 218.5 218.4z"
            />
            <path
              id="path3799"
              fill="none"
              d="m1270.9 841.5c-174-56.6-323.8-60.7-437.1-48.5 36.4-93.1 40.5-190.1 24.3-283.2 149.7 105.2 287.3 218.4 412.8 331.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
