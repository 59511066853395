import { CitationResponse } from "../generated/protos/api";

export default async function getCitation(
  baseUrl: string,
  chunkId: string,
  signal?: AbortSignal
) {
  const response = await fetch(`${baseUrl}/citations/${chunkId}`, {
    method: "GET",
    headers: {
      Accept: "application/octet-stream",
    },
    signal,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const rawData = await response.json();
  const citationResponse = CitationResponse.fromJSON(JSON.parse(rawData));

  const pdfBlob = new Blob([citationResponse.documentBytes], {
    type: "application/pdf",
  });

  return {
    citationResponse,
    pdfBlob,
  };
}
