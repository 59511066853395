import React, { useState, useRef, useContext } from "react";
import "../../styles/FileUploader.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import uploadDocument from "../../api/documents";
import { useAuth } from "../../context/AuthContext";
import { BaseUrlContext } from "../../Contexts";
import { useDocumentContext } from "../../context/DocumentContext";

const MAX_FILE_SIZE_MB = 200;

function BinaryFileReader(file: File): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result));
      } else {
        reject("Failed to read file as binary data.");
      }
    };
    reader.onerror = () => {
      reject("Failed to read file as binary data.");
    };
    reader.readAsArrayBuffer(file);
  });
}

export default function FileUploader() {
  const { allDocuments } = useDocumentContext();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [binaryDataArray, setBinaryDataArray] = useState<Uint8Array[]>([]);
  const [previewURLs, setPreviewURLs] = useState<string[]>([]);
  const [activePreviewIndex, setActivePreviewIndex] = useState<number | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const baseUrl = useContext(BaseUrlContext);
  const { username } = useAuth();
  const queryClient = useQueryClient();

  const uploadMutation = useMutation({
    mutationFn: async ({
      fileName,
      binaryData,
    }: {
      fileName: string;
      binaryData: Uint8Array;
    }) => uploadDocument(baseUrl, username, fileName, binaryData),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["documentExtractions"] }),
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) => {
        const isValidType = file.type === "application/pdf";
        const isValidSize = file.size / (1024 * 1024) <= MAX_FILE_SIZE_MB;
        if (!isValidType) {
          alert(`${file.name} is not a valid PDF file.`);
        }
        if (!isValidSize) {
          alert(
            `${file.name} exceeds the size limit of ${MAX_FILE_SIZE_MB}MB.`
          );
        }

        return isValidType && isValidSize;
      });

      if (validFiles.length > 0) {
        setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
        const urls = validFiles.map((file) => URL.createObjectURL(file));
        setPreviewURLs((prevURLs) => [...prevURLs, ...urls]);
        try {
          const fileDataPromises = validFiles.map((file) =>
            BinaryFileReader(file)
          );
          const fileDataArray = await Promise.all(fileDataPromises);
          setBinaryDataArray((prevData) => [...prevData, ...fileDataArray]);
        } catch (error) {
          console.error("Error reading file data:", error);
        }
      }
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileClick = (index: number) => {
    setActivePreviewIndex(index);
  };

  const handleUpload = async () => {
    if (uploadedFiles.length === 0 || binaryDataArray.length === 0) {
      alert("Please select at least one file before uploading.");
      return;
    }

    try {
      await Promise.all(
        binaryDataArray.map((binaryData, index) =>
          uploadMutation.mutateAsync({
            fileName: uploadedFiles[index].name,
            binaryData,
          })
        )
      );
      setUploadedFiles([]);
      setBinaryDataArray([]);
      setPreviewURLs([]);
      setActivePreviewIndex(null);
    } catch (error) {
      console.error("File upload failed:", error);
      alert("File upload failed. Please try again.");
    }
  };

  const handleRemoveFile = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviewURLs((prevURLs) => prevURLs.filter((_, i) => i !== index));
    setBinaryDataArray((prevData) => prevData.filter((_, i) => i !== index));
    if (activePreviewIndex === index) {
      setActivePreviewIndex(null);
    }
  };

  return (
    <div className="file-uploader-content">
      <div className="drag-drop-area">
        <input
          type="file"
          accept="application/pdf"
          className="file-input"
          onChange={handleFileChange}
          ref={fileInputRef}
          multiple
          style={{ display: "none" }}
        />
        <div className="file-placeholder">
          <span className="file-icon">📄</span>
          <p>
            {uploadedFiles.length > 0
              ? `${uploadedFiles.length} files selected`
              : "Drag and Drop Files Here"}
          </p>
          <p>Limit 200MB per file • PDF</p>
          <button className="browse-button" onClick={handleBrowseClick}>
            Browse Files
          </button>
        </div>
      </div>

      <button
        className={`upload-button ${
          uploadedFiles.length === 0 ? "disabled" : ""
        }`}
        onClick={handleUpload}
        disabled={uploadedFiles.length === 0 || uploadMutation.isPending}
      >
        {uploadMutation.isPending ? "Uploading..." : "Upload Files"}
      </button>

      {uploadedFiles.length > 0 && (
        <div
          className={`file-preview-list ${
            uploadedFiles.length === 1 ? "single-file" : ""
          }`}
        >
          {uploadedFiles.map((file, index) => (
            <div
              key={index}
              className="file-preview-item"
              onClick={() => handleFileClick(index)}
            >
              <span className="file-name" title={file.name}>
                {file.name.length > 50
                  ? `${file.name.slice(0, 50)}...`
                  : file.name}
              </span>
              <button
                className="remove-file-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFile(index);
                }}
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      )}

      {activePreviewIndex !== null && previewURLs[activePreviewIndex] && (
        <div className="file-preview-container">
          <embed
            src={previewURLs[activePreviewIndex]}
            type="application/pdf"
            width="100%"
            height="500px"
            className="file-preview"
          />
        </div>
      )}
    </div>
  );
}
