import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { X } from "lucide-react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { DocumentExtraction } from "../../generated/protos/document";
import { BaseUrlContext } from "../../Contexts";
import { useQuery } from "@tanstack/react-query";
import getCitation from "../../api/citations";
import MetricTable from "../MetricTable";
import { MetricTableRow, relationshipChunksToMetricTableRows } from "../Dashboard/tableDataAggregation";
import { Chunk } from "../../generated/protos/chunk";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: "https://unpkg.com/pdfjs-dist@4.8.69/cmaps/",
  standardFontDataUrl: "https://unpkg.com/pdfjs-dist@4.8.69/standard_fonts/",
};

const maxWidth = 1000;
const resizeObserverOptions = {};

export default function PreviewModal({
  document,
  open,
  onClose,
}: {
  document: DocumentExtraction | null;
  open: boolean;
  onClose: () => void;
}) {
  const baseUrl = useContext(BaseUrlContext);
  const [numPages, setNumPages] = useState<number>(0);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages: nextNumPages }: { numPages: number }) => {
      setNumPages(nextNumPages);
    },
    []
  );

  const metricDataByPage = useMemo((): Record<number, MetricTableRow[]> => {
    if (!document?.relationshipChunks)
      return {} as Record<number, MetricTableRow[]>;

    const chunksByPage = document.relationshipChunks.reduce((acc, chunk) => {
      const pageNum = chunk.pageNum;
      if (!acc[pageNum]) {
        acc[pageNum] = [];
      }
      acc[pageNum].push(chunk);
      return acc;
    }, {} as Record<number, Chunk[]>);


    return Object.entries(chunksByPage).reduce((acc, [pageNum, chunks]) => {
      const tableRowsForPage = relationshipChunksToMetricTableRows(chunks);

      if (tableRowsForPage.length > 0) {
        acc[Number(pageNum)] = tableRowsForPage;
      }

      return acc;
    }, {} as Record<number, MetricTableRow[]>);

  }, [document]);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  const { data, isLoading, error } = useQuery({
    queryKey: [
      "citations",
      document?.sourceChunks?.length ? document.sourceChunks[0]?.chunkId : null,
    ],
    queryFn: ({ signal }) => {
      if (
        !document?.sourceChunks?.length ||
        !document.sourceChunks[0]?.chunkId
      ) {
        return undefined;
      }
      return getCitation(baseUrl, document.sourceChunks[0].chunkId, signal);
    },
    enabled:
      !!document?.sourceChunks?.length && !!document.sourceChunks[0]?.chunkId,
  });

  const pdfUrl = useMemo(() => {
    try {
      if (!data?.citationResponse?.documentBytes) return null;
      const blob = new Blob([data.citationResponse.documentBytes], {
        type: "application/pdf",
      });
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error creating PDF URL:", error);
      return null;
    }
  }, [data]);
  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  const renderPageAndChunks = (pageNumber: number) => {
    const pageMetrics: MetricTableRow[] = metricDataByPage[pageNumber] || [];
    const width = containerWidth
      ? Math.min(containerWidth * 0.6, maxWidth)
      : maxWidth;

    return (
      <Box
        key={pageNumber}
        sx={{ display: "flex", mb: 4, gap: 4, justifyContent: "center" }}
      >
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: 2,
            boxShadow:
              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              "& .react-pdf__Page": {
                boxShadow:
                  "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                bgcolor: "#fff",
                borderRadius: 1,
                overflow: "hidden",
              },
            }}
          >
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              width={width}
              renderAnnotationLayer={true}
              renderTextLayer={true}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          {pageMetrics.length > 0 ? (
            <MetricTable
              metricTableRows={pageMetrics}
              tableName={`Page ${pageNumber} Metrics`}
              preview={true}
              showMetricName={true}
            />
          ) : (
            <Typography
              color="text.secondary"
              sx={{ textAlign: "center", mt: 2 }}
            >
              No metrics available for this page
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="citation-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6">
            {document?.documentMetadata?.documentName}
          </Typography>
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </Box>

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Typography color="error">
              Failed to load document: {(error as Error).message}
            </Typography>
          </Box>
        ) : (
          <Box
            ref={setContainerRef}
            sx={{
              overflowY: "auto",
              flex: 1,
              px: 2,
            }}
          >
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              loading={
                <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                  <CircularProgress />
                </Box>
              }
              error={
                <Typography color="error">
                  Failed to load PDF. Please check if the file is valid.
                </Typography>
              }
            >
              {numPages > 0 &&
                Array.from(new Array(numPages), (_, index) =>
                  renderPageAndChunks(index + 1)
                )}
            </Document>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

function useResizeObserver(
  element: Element | null,
  options: ResizeObserverOptions | undefined,
  observerCallback: ResizeObserverCallback
): void {
  useEffect(() => {
    if (!element || !("ResizeObserver" in window)) {
      return undefined;
    }

    const observer = new ResizeObserver(observerCallback);

    observer.observe(element, options);

    return () => {
      observer.disconnect();
    };
  }, [element, options, observerCallback]);
}
