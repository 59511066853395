import RecentCalls from "./RecentCalls";
import TradeRecommendations from "./TradeRecomendations";
import { Box, Container } from "@mui/material";

export default function TradeNRecentCalls() {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          padding: 3,
          overflowX: "auto",
          overflowY: "hidden", // Add this to hide vertical scroll
          minHeight: 600,
          "& > *": {
            flex: "1 0 0",
            minWidth: "500px",
            maxWidth: "50%",
            overflowY: "hidden", // Add this to hide vertical scroll in children
          },
          "&::-webkit-scrollbar": {
            height: "8px",
            width: "0px", // Add this to hide vertical scrollbar
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
          msOverflowStyle: "none", // Hide scrollbar for IE/Edge
          scrollbarWidth: "none", // Hide scrollbar for Firefox
        }}
      >
        <RecentCalls />
        <TradeRecommendations />
      </Box>
    </Container>
  );
}
