export default function Bofa({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      viewBox="0 0 152.000000 152.000000"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
    >
      <g
        transform="translate(0.000000,152.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M691 1156 l-34 -12 69 -19 c38 -10 107 -33 154 -52 l85 -33 49 16 49 17 -49 18 c-100 39 -235 79 -262 78 -15 0 -42 -6 -61 -13z" />
        <path d="M501 1091 l-43 -18 28 -11 c16 -6 60 -21 99 -33 38 -12 93 -33 122 -45 l52 -23 53 23 52 23 -30 16 c-44 23 -212 77 -253 82 -21 2 -54 -4 -80 -14z" />
        <path d="M245 972 c-109 -58 -234 -132 -240 -142 -3 -4 16 -15 42 -25 41 -16 50 -16 69 -4 114 70 218 132 259 154 68 36 71 41 34 54 -51 18 -68 14 -164 -37z" />
        <path d="M1043 986 c-118 -43 -240 -103 -373 -184 -102 -62 -257 -169 -266 -184 -2 -4 22 -25 53 -46 l58 -40 35 30 c53 44 214 153 315 213 109 64 211 113 329 159 l89 34 -52 26 c-29 14 -62 26 -74 26 -12 -1 -63 -16 -114 -34z" />
        <path d="M474 921 c-85 -44 -284 -173 -284 -183 0 -4 23 -19 51 -34 l51 -26 37 27 c66 49 225 152 274 178 53 28 53 28 -24 60 -35 14 -38 14 -105 -22z" />
        <path d="M1325 876 c-153 -59 -346 -170 -552 -318 -160 -115 -155 -105 -78 -162 l64 -47 88 69 c178 140 381 267 571 361 l103 50 -38 24 c-51 32 -80 47 -90 47 -4 -1 -35 -11 -68 -24z" />
        <path d="M835 380 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z" />
      </g>
    </svg>
  );
}
