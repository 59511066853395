export const COLORS = {
  primary: {
    main: "#4F46E5",
    hover: "#4338CA",
    light: "rgba(79, 70, 229, 0.1)",
  },
  grey: {
    50: "#F8FAFC",
    100: "#F1F5F9",
    200: "#E2E8F0",
    300: "#CBD5E1",
    400: "#94A3B8",
    500: "#64748B",
    600: "#475569",
    700: "#334155",
  },
  white: "#FFFFFF",
  shadow: {
    sm: "0 1px 2px rgba(0, 0, 0, 0.05)",
    md: "0 4px 6px rgba(0, 0, 0, 0.05)",
    lg: "0 4px 12px rgba(79, 70, 229, 0.1)",
  },
} as const;
