import React, { createContext, useContext, useState, ReactNode } from "react";
import { ChatMessage } from "../generated/protos/chat";

interface ChatMessagesContextType {
  chatMessages: ChatMessage[];
  setChatMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
}

const ChatMessagesContext = createContext<ChatMessagesContextType | undefined>(undefined);

export const ChatMessagesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  return (
    <ChatMessagesContext.Provider value={{ chatMessages, setChatMessages }}>
      {children}
    </ChatMessagesContext.Provider>
  );
};

export const useChatMessagesContext = () => {
  const context = useContext(ChatMessagesContext);
  if (!context) {
    throw new Error("useChatMessagesContext must be used within a ChatMessagesProvider");
  }
  return context;
};
