import { useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Popover,
  Switch,
  Typography,
} from "@mui/material";
import { relationshipChunksToMetricTables } from "./tableDataAggregation";
import MetricTable from "../MetricTable";
import { Card } from "./Card";
import { useDocumentContext } from "../../context/DocumentContext";
import { isAfter, isBefore, addMonths } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// For both LoadingState and EmptyState, we should use consistent heights and layouts:
const containerStyles = {
  minHeight: '300px', // Or whatever minimum height works for your design
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
};

const LoadingState = () => (
  <Box
    sx={{
      ...containerStyles,
      flexDirection: "column",
      gap: 1,
    }}
  >
    <CircularProgress size={24} />
    <Typography variant="h6" color="textSecondary">
      Loading Report Data...
    </Typography>
  </Box>
);

const EmptyState = () => (
  <Box
    sx={{
      ...containerStyles,
      flexDirection: "column",
      gap: 1,
    }}
  >
    <Typography variant="h6" color="textSecondary">
      No report data found.
    </Typography>
    <Typography variant="body2" color="textSecondary">
      Upload a report to get started.
    </Typography>
  </Box>
);
function DateRangeFilter({
  startDate,
  endDate,
  onDateChange,
}: {
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: (key: "start" | "end", date: Date | null) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleReset = () => {
    onDateChange("start", null);
    onDateChange("end", null);
    setAnchorEl(null);
  };

  const hasActiveFilters = startDate || endDate;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: 2 }}>
        <Button
          startIcon={<CalendarTodayIcon />}
          variant="outlined"
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            backgroundColor: "white",
          }}
        >
          Target Date {hasActiveFilters && "✓"}
        </Button>
        {hasActiveFilters && (
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={handleReset}
            sx={{ minWidth: "auto", p: "4px 8px" }}
          >
            ✕
          </Button>
        )}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => onDateChange("start", newValue)}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => onDateChange("end", newValue)}
          />
          {hasActiveFilters && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleReset}
              fullWidth
            >
              Reset Dates
            </Button>
          )}
        </Box>
      </Popover>
    </LocalizationProvider>
  );
}

function MetricTableDashboard() {
  const {
    allDocuments,
    selectedDocuments,
  } = useDocumentContext();

  const [showFutureOnly, setShowFutureOnly] = useState(false);
  const [showSingleSubcomponentOnly, setShowSingleSubcomponentOnly] =
    useState(false);
  const [filters, setFilters] = useState({
    targetDateRange: { start: null, end: null },
  });

  const Filteredtables = useMemo(() => {
    // Create a Set to track unique IDs for deduplication

    const aggregatedEntityRelationships = allDocuments
      .filter((doc) =>
        selectedDocuments.some(
          (selectedDoc) =>
            selectedDoc.documentId === doc.documentMetadata?.documentId
        )
      )
      .flatMap((doc) => doc.relationshipChunks);


    const now = new Date();
    const oneMonthFromNow = addMonths(now, 1);

    const timeFilteredRelationships = aggregatedEntityRelationships.filter(
      (chunk) => {
        const rawEndDate = chunk?.entityRelationship?.timeSlice?.endTimestamp;
        if (!rawEndDate) return false;

        const relationEndDate =
          rawEndDate instanceof Date ? rawEndDate : new Date(rawEndDate);

        if (isNaN(relationEndDate.getTime())) return false;

        if (showSingleSubcomponentOnly) {
          return (
            isAfter(relationEndDate, now) &&
            isBefore(relationEndDate, oneMonthFromNow)
          );
        }

        let shouldInclude = true;

        if (showFutureOnly) {
          shouldInclude = isAfter(relationEndDate, now);
        }

        const { start, end } = filters.targetDateRange;

        if (start) {
          shouldInclude =
            shouldInclude && isAfter(relationEndDate, new Date(start));
        }

        if (end) {
          shouldInclude =
            shouldInclude && isBefore(relationEndDate, new Date(end));
        }

        return shouldInclude;
      }
    );
    const tables = relationshipChunksToMetricTables(timeFilteredRelationships);
    const filteredTables = tables
      .filter((table) =>
        showSingleSubcomponentOnly
          ? table.entity.entitySubcomponents.length === 1
          : true
      );
    return filteredTables;
  }, [
    allDocuments,
    selectedDocuments,
    showFutureOnly,
    filters,
    showSingleSubcomponentOnly,
  ]);

  const handleDateChange = (key: "start" | "end", date: Date | null) => {
    setFilters((prev) => ({
      ...prev,
      targetDateRange: {
        ...prev.targetDateRange,
        [key]: date,
      },
    }));
  };
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          padding: 2,
          paddingBottom: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={showFutureOnly}
              onChange={() => setShowFutureOnly((prev) => !prev)}
              disabled={showSingleSubcomponentOnly}
              color="primary"
            />
          }
          sx={{ letterSpacing: "-0.02em" }}
          label="Future Target Dates Only"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showSingleSubcomponentOnly}
              onChange={() => {
                setShowSingleSubcomponentOnly((prev) => !prev);
                // Reset other filters when entering Simple Mode
                if (!showSingleSubcomponentOnly) {
                  setShowFutureOnly(false);
                  setFilters({
                    targetDateRange: { start: null, end: null },
                  });
                }
              }}
              color="primary"
            />
          }
          sx={{ letterSpacing: "-0.02em", marginLeft: 2 }}
          label="Simple Mode"
        />
        {!showSingleSubcomponentOnly && (
          <DateRangeFilter
            startDate={filters.targetDateRange.start}
            endDate={filters.targetDateRange.end}
            onDateChange={handleDateChange}
          />
        )}
      </Box>
      <Box
        sx={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            padding: 2,
            minWidth: "min-content",
          }}
        >
          {Filteredtables.length === 0 ? (
            <EmptyState />
          ) : (
            Filteredtables.filter((table) => table.rows.length > 0).map(
              (table) => (
                <Box
                  key={table.title}
                  sx={{ verticalAlign: "top", width: "600px" }}
                >
                  <Card>
                    <MetricTable
                      metricTableRows={table.rows}
                      tableName={table.title}
                      isSimpleMode={showSingleSubcomponentOnly}
                      disableFilters={showSingleSubcomponentOnly}
                    />
                  </Card>
                </Box>
              )
            )
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default MetricTableDashboard;
