import { useAuth } from "../context/AuthContext";
import { useNavigation } from "../context/NavigationContext";
import { LogOut, Grid, Bot, ChartLine, User, FileText } from "lucide-react";
import { Box, Typography, IconButton, Button, Drawer } from "@mui/material";
import { useState } from "react";
import Sidebar from "./Sidebar";
import Chat from "./Chat";

export default function Header() {
  const { role, username, logout } = useAuth();
  const { activeComponent, setActiveComponent } = useNavigation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showChatDrawer, setShowChatDrawer] = useState(false);

  const navItems = [
    { name: "Dashboard", icon: Grid },
    { name: "Analysis", icon: ChartLine },
  ] as const;

  return (
    <Box>
      <Box
        component="header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "14px 20px",
          backgroundColor: "white",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: "24px",
              background: "linear-gradient(135deg, #2563eb, #4f46e5)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.02em",
            }}
          >
            MetisLLM
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {navItems.map((item) => (
              <Button
                key={item.name}
                onClick={() => setActiveComponent(item.name)}
                startIcon={<item.icon size={18} />}
                sx={{
                  padding: "6px 18px",
                  borderRadius: 2,
                  fontWeight: 600,
                  fontSize: 14,
                  color: activeComponent === item.name ? "white" : "#4a5568",
                  backgroundColor:
                    activeComponent === item.name ? "#3b4c9b" : "white",
                  "&:hover": {
                    backgroundColor:
                      activeComponent === item.name ? "#2c3a7d" : "#f1f1f1",
                    boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              startIcon={<FileText size={18} />}
              onClick={() => setShowSidebar(true)}
              sx={{
                padding: "6px 18px",
                borderRadius: 2,
                fontWeight: 600,
                fontSize: 14,
                color: "#4a5568",
                backgroundColor: "#f1f1f1",
                "&:hover": {
                  boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                },
              }}
            >
              Select Reports
            </Button>
            <Button
              startIcon={<Bot size={18} />}
              onClick={() => setShowChatDrawer(true)}
              sx={{
                padding: "6px 18px",
                borderRadius: 2,
                fontWeight: 600,
                fontSize: 14,
                color: "#4a5568",
                backgroundColor: "#f1f1f1",
                "&:hover": {
                  boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                },
              }}
            >
              ASK METIS
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#333",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <User size={16} />
                {username}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#666",
                }}
              >
                {role}
              </Typography>
            </Box>
            <IconButton
              onClick={logout}
              title="Logout"
              sx={{
                color: "#666",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  color: "#4a148c",
                },
              }}
            >
              <LogOut size={18} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={showSidebar}
        onClose={() => setShowSidebar(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "90vw", sm: 550 },
            padding: 2,
            boxShadow: "-2px 0 8px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Sidebar onClose={() => setShowSidebar(false)} />
      </Drawer>
      <Drawer
        anchor="right"
        open={showChatDrawer}
        onClose={() => setShowChatDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100vw", sm: 800 },

            backgroundColor: "#fafafa",
            backgroundImage:
              "radial-gradient(at 50% 50%, #f8fafc 0%, #f1f5f9 100%)",
            color: "white",
            boxShadow: "-2px 0 8px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Chat />
      </Drawer>
    </Box>
  );
}
