import MetricTableDashboard from "./MetricTableDashboard";
import KeyThemeAccordions from "./KeyThemeAccordions";
import TradeNRecentCalls from "./TradeNRecentCalls";

export default function Dashboard() {
  return (
    <>
      <MetricTableDashboard />
      <KeyThemeAccordions />
      <TradeNRecentCalls />
    </>
  );
}
