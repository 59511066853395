import React, { createContext, useContext, useState, ReactNode } from "react";
import { KeyTheme } from "../generated/protos/api";

interface KeyThemesContextType {
  keyThemes: KeyTheme[];
  setKeyThemes: React.Dispatch<React.SetStateAction<KeyTheme[]>>;
}

const KeyThemesContext = createContext<KeyThemesContextType | undefined>(
  undefined
);

export const KeyThemesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [keyThemes, setKeyThemes] = useState<KeyTheme[]>([]);

  return (
    <KeyThemesContext.Provider value={{ keyThemes, setKeyThemes }}>
      {children}
    </KeyThemesContext.Provider>
  );
};

export const useKeyThemesContext = () => {
  const context = useContext(KeyThemesContext);
  if (!context) {
    throw new Error(
      "useKeyThemesContext must be used within a KeyThemesProvider"
    );
  }
  return context;
};
