import { KeyThemesRequest, KeyThemesResponse } from "../generated/protos/api";
import {
  DocumentExtraction,
  DocumentMetadata,
} from "../generated/protos/document";

export default async function fetchKeyThemes(
  baseUrl: string,
  selectedDocuments: DocumentMetadata[],
  allDocuments: DocumentExtraction[],
  signal?: AbortSignal
) {
  const body = KeyThemesRequest.create({
    selectedDocumentExtractions: allDocuments.filter((doc) =>
      selectedDocuments.some(
        (selectedDoc) =>
          selectedDoc.documentId === doc.documentMetadata?.documentId
      )
    ),
  });

  const response = await fetch(`${baseUrl}/key-themes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    signal,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const rawData = await response.json();
  return KeyThemesResponse.fromJSON(JSON.parse(rawData));
}
