import { useRef, useEffect } from "react";
import Chart, { ChartDataset } from "chart.js/auto";
import 'chartjs-adapter-luxon';

type AccuraciesChartProps = {
	datasets: ChartDataset[],
	min?: number,
	max?: number
};

const AccuraciesChart: React.FC<AccuraciesChartProps> = ({ datasets, min, max }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
	// Get the canvas context, returning if it isn't ready
    const ctx = canvasRef.current?.getContext("2d");
	if (!ctx) return;

	// Destroy the existing chart if it exists
    if (chartRef.current) chartRef.current.destroy();

	// Render the chart onto the DOM
	chartRef.current = new Chart(ctx, {
		type: "line",
		data: {
			datasets
		},
		options: {
			responsive: true,
			plugins: {
				title: {
					display: true,
					text: 'Absolute Error Over Time',
					position: 'bottom'
				},
				legend: {
					position: "top",
				},
				tooltip: {
					callbacks: {
						title: (context) => new Date((context[0].raw as { x: number }).x).toLocaleString("en-US", { timeZone: 'UTC', year: "numeric", month: "long" }),
					},
				},
			},
			scales: {
				x: {
					type: 'time',
					time: {
						unit: 'month',
					},
					min,
					max,
					adapters: {
						date: {
							zone: 'UTC'
						}
					}
				}
			},
		},
	});

	// Cleanup function to destroy the chart instance
    return () => {
		if (chartRef.current) {
			chartRef.current.destroy();
			chartRef.current = null;
		}
	};
  }, [datasets, min, max]);

  return <canvas ref={canvasRef} style={{ padding: '16px' }} />;
};

export default AccuraciesChart;
