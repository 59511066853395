export default function CitiGroup({
  height = 24,
  width = 24,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 192.756 192.756"
      fill="currentColor"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M92.574 76.926H77.318v74.605h15.256V76.926zM106.562 77.049v13.988h12.844v42.089c0 5.807 1.594 10.471 4.662 13.988 3.068 3.641 7.158 5.439 12.312 5.562 5.111.123 9.652-1.023 13.498-3.395l.244-.123v-.246l3.641-14.193-.9.572c-4.09 2.496-7.852 3.723-11.289 3.723-4.744 0-7.156-2.822-7.156-8.385V91.038H150V77.049h-15.582V53.735l-15.012 8.058v15.256h-12.844zM180.391 76.926h-15.379v74.605h15.379V76.926zM68.565 88.666l-.328-.246c-7.035-8.548-15.829-12.721-26.382-12.721-11.043 0-20.124 3.395-27.405 10.103-7.73 7.076-11.616 16.606-11.616 28.549s3.886 21.393 11.616 28.551c7.158 6.707 16.361 10.021 27.405 10.021 10.553 0 19.428-4.213 26.382-12.639l.328-.246-8.631-10.43-.327.326c-5.604 5.562-11.616 8.426-18.079 8.426-6.749 0-12.312-2.291-16.729-6.83-4.459-4.459-6.585-10.227-6.585-17.18s2.126-12.762 6.585-17.301c4.417-4.418 9.98-6.708 16.729-6.708 6.462 0 12.475 2.863 18.079 8.425l.327.328 8.631-10.428z" />
        <path d="M84.843 64.656c6.013-6.135 12.721-10.798 20.37-14.111 7.363-3.19 15.215-4.745 23.314-4.745 8.059 0 15.91 1.554 23.438 4.745 7.484 3.313 14.191 7.976 20.205 14.111l.246.205h17.506l-.654-1.022c-6.953-9.776-15.953-17.506-26.75-23.151-10.799-5.604-22.047-8.344-33.99-8.344-11.82 0-23.232 2.74-34.031 8.344-10.799 5.644-19.797 13.374-26.71 23.15l-.695 1.022h17.629l.122-.204z" />
        <path d="M92.574 76.926H77.318v74.605h15.256V76.926zM106.562 77.049v13.988h12.844v42.089c0 5.807 1.594 10.471 4.662 13.988 3.068 3.641 7.158 5.439 12.312 5.562 5.111.123 9.652-1.023 13.498-3.395l.244-.123v-.246l3.641-14.193-.9.572c-4.09 2.496-7.852 3.723-11.289 3.723-4.744 0-7.156-2.822-7.156-8.385V91.038H150V77.049h-15.582V53.735l-15.012 8.058v15.256h-12.844zM180.391 76.926h-15.379v74.605h15.379V76.926zM68.565 88.666l-.328-.246c-7.035-8.548-15.829-12.721-26.382-12.721-11.043 0-20.124 3.395-27.405 10.103-7.73 7.076-11.616 16.606-11.616 28.549s3.886 21.393 11.616 28.551c7.158 6.707 16.361 10.021 27.405 10.021 10.553 0 19.428-4.213 26.382-12.639l.328-.246-8.631-10.43-.327.326c-5.604 5.562-11.616 8.426-18.079 8.426-6.749 0-12.312-2.291-16.729-6.83-4.459-4.459-6.585-10.227-6.585-17.18s2.126-12.762 6.585-17.301c4.417-4.418 9.98-6.708 16.729-6.708 6.462 0 12.475 2.863 18.079 8.425l.327.328 8.631-10.428z" />
        <path d="M84.843 64.656c6.013-6.135 12.721-10.798 20.37-14.111 7.363-3.19 15.215-4.745 23.314-4.745 8.059 0 15.91 1.554 23.438 4.745 7.484 3.313 14.191 7.976 20.205 14.111l.246.205h17.506l-.654-1.022c-6.953-9.776-15.953-17.506-26.75-23.151-10.799-5.604-22.047-8.344-33.99-8.344-11.82 0-23.232 2.74-34.031 8.344-10.799 5.644-19.797 13.374-26.71 23.15l-.695 1.022h17.629l.122-.204z" />
      </g>
    </svg>
  );
}
