import {
  DocumentExtractionRequest,
  DocumentExtractionResponse,
} from "../generated/protos/api";

export async function getDocumentExtractions(
  baseUrl: string,
  documentExtractionRequest: DocumentExtractionRequest
) {
  const response = await fetch(`${baseUrl}/document-extractions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(documentExtractionRequest),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const rawData = await response.json();
  return DocumentExtractionResponse.fromJSON(JSON.parse(rawData));
}

export async function deleteDocument(baseUrl: string, documentID: string) {
  const response = await fetch(
    `${baseUrl}/document-extractions/${encodeURIComponent(documentID)}`,
    { method: "DELETE" }
  );

  if (!response.ok) {
    throw new Error(`File delete failed with status ${response.status}`);
  }
}
