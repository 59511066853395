import {
  Value,
  Entity,
  Chunk,
  Company,
  Unit,
} from "../../generated/protos/chunk";

export interface MetricTable {
  title: string;
  entity: Entity;
  rows: MetricTableRow[];
}

export interface MetricTableRow {
  metricName: string;
  firm: string;
  metricValue: string;
  qualifier: string;
  targetDate: string;
  chunkId: string;
  pageNum: string;
  publishingOrganization: Company;
}

export function relationshipChunksToMetricTables(
  relationshipChunks: Chunk[]
): MetricTable[] {
  const entityToRelationships = new Map<string, Array<Chunk>>();
  relationshipChunks.forEach((chunk) => {
    let entity = chunk.entityRelationship?.toEntity;
    if (entity) {
      const entityKey = JSON.stringify(entity);
      // can't use the object as the key because object's are compared by reference not value
      if (!entityToRelationships.has(entityKey)) {
        entityToRelationships.set(entityKey, []);
      }
      entityToRelationships.get(entityKey)?.push(chunk);
    }
  });

  let metricTables: MetricTable[] = [];
  entityToRelationships.forEach((chunks, _) => {
    const entity = chunks[0]?.entityRelationship?.toEntity as Entity;
    const dedupedChunks = deduplicateToEntityGroupedRelationships(chunks);
    const table: MetricTable = {
      title: entityToMetricName(entity),
      entity: entity,
      rows: relationshipChunksToMetricTableRows(dedupedChunks),
    };
    metricTables.push(table);
  });
  // least nested entities first
  metricTables.sort(
    (a, b) =>
      a.entity.entitySubcomponents.length - b.entity.entitySubcomponents.length
  );

  return metricTables;
}

function deduplicateToEntityGroupedRelationships(chunks: Chunk[]): Chunk[] {
  const dedupedChunks = new Map<string, Chunk>();
  chunks.forEach((chunk) => {
    const fromKey = JSON.stringify(chunk.entityRelationship?.fromEntity);
    const timePeriodKey = `${chunk.entityRelationship?.timeSlice?.startTimestamp}-${chunk.entityRelationship?.timeSlice?.endTimestamp}`;
    const valueKey = `${chunk.entityRelationship?.value?.unit}-${chunk.entityRelationship?.value?.qualifier}`;

    const key = `${fromKey}-${timePeriodKey}-${valueKey}`;

    const asOfTimestamp = chunk.entityRelationship?.timeSlice
      ?.asOfTimestamp as Date;

    let existingAsOfTimestamp: Date | undefined = undefined;
    if (dedupedChunks.has(key)) {
      existingAsOfTimestamp =
        dedupedChunks.get(key)?.entityRelationship?.timeSlice?.asOfTimestamp;
    }

    if (existingAsOfTimestamp === undefined) {
      dedupedChunks.set(key, chunk);
    } else if (
      existingAsOfTimestamp !== undefined &&
      asOfTimestamp > existingAsOfTimestamp
    ) {
      dedupedChunks.set(key, chunk);
    }
  });

  const result = Array.from(dedupedChunks.values());
  return result;
}

export function relationshipChunksToMetricTableRows(
  relationshipChunks: Chunk[]
): MetricTableRow[] {
  const rows = relationshipChunks
    .filter(isValidRelationshipChunk)
    .map((chunk) => {
      const targetDate = chunk?.entityRelationship?.timeSlice?.endTimestamp
        ? new Date(chunk.entityRelationship.timeSlice.endTimestamp)
            .toISOString()
            .split("T")[0]
        : "N/A";
      const value = chunk?.entityRelationship?.value;
      const relEntity = chunk?.entityRelationship?.toEntity as Entity;
      const metricTableRow: MetricTableRow = {
        metricName: entityToMetricName(relEntity),
        chunkId: chunk.chunkId,
        pageNum: String(chunk.pageNum),
        firm: chunk?.entityRelationship?.fromEntity?.entityName || "",
        metricValue: formatValue(value as Value),
        qualifier: value?.qualifier || "",
        targetDate: targetDate,
        publishingOrganization:
          chunk?.metadata?.publishingOrganization || Company.UNSPECIFIED,
      };
      return metricTableRow;
    });

  return rows;
}

const formatUnit = (unit: Unit): string => {
  switch (unit) {
    case Unit.UNIT_PERCENTAGE:
      return "%";
    case Unit.UNIT_BPS:
      return "bps";
    default:
      return "";
  }
};

function isValidRelationshipChunk(chunk: Chunk): boolean {
  return (
    chunk.entityRelationship !== undefined &&
    chunk.entityRelationship.toEntity !== undefined &&
    chunk.entityRelationship.value !== undefined &&
    chunk.entityRelationship.timeSlice !== undefined &&
    chunk.metadata !== undefined
  );
}

function entityToMetricName(entity: Entity): string {
  if (entity.entitySubcomponents.length === 0) {
    return entity.entityName;
  }

  const lastSubcomponent =
    entity.entitySubcomponents[entity.entitySubcomponents.length - 1];
  return `${lastSubcomponent} ${entity.entityName}`;
}

function formatValue(value: Value): string {
  let formattedValue = value?.value;

  // make large numbers human readable
  // 3000000 -> 3M, -3000 -> 3k
  if (!isNaN(Number(formattedValue))) {
    const numericValue = parseFloat(formattedValue);
    const absValue = Math.abs(numericValue);

    if (absValue >= 1000) {
      if (absValue >= 1000000) {
        formattedValue =
          (numericValue / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      } else {
        formattedValue =
          (numericValue / 1000).toFixed(1).replace(/\.0$/, "") + "k";
      }
    }
  }

  return `${formattedValue}${formatUnit(value?.unit || Unit.UNIT_UNSPECIFIED)}`;
}
