import { useMemo, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { format, addDays, subDays } from "date-fns";

// Define the core interfaces we need
interface TradeCall {
  id: string;
  firm: string;
  trade: string;
  startDate: Date;
  endDate: Date;
}

// Dummy data constants
const DUMMY_FIRMS = [
  "JP Morgan",
  "Goldman Sachs",
  "Morgan Stanley",
  "Bank of America",
  "Citigroup",
  "Wells Fargo",
  "UBS",
  "Deutsche Bank",
];

const DUMMY_TRADES = [
  "Buy AAPL",
  "Sell TSLA",
  "Hold MSFT",
  "Buy GOOGL",
  "Sell AMZN",
  "Buy META",
  "Sell NVDA",
  "Buy NFLX",
  "Hold AMD",
  "Sell INTC",
];

// Dummy data generator
const generateDummyTradeCalls = (count: number): TradeCall[] => {
  return Array.from({ length: count }).map((_, index) => {
    const today = new Date();
    const startDate = subDays(today, Math.floor(Math.random() * 30));
    const endDate = addDays(startDate, Math.floor(Math.random() * 60) + 30);

    return {
      id: `trade-${index}`,
      firm: DUMMY_FIRMS[Math.floor(Math.random() * DUMMY_FIRMS.length)],
      trade: DUMMY_TRADES[Math.floor(Math.random() * DUMMY_TRADES.length)],
      startDate,
      endDate,
    };
  });
};

// Loading state component
const LoadingState = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1,
      py: 10,
    }}
  >
    <CircularProgress size={24} />
    <Typography variant="h6" color="textSecondary">
      Loading Call Data...
    </Typography>
  </Box>
);

// Empty state component
const EmptyState = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1,
      py: 4,
    }}
  >
    <Typography variant="h6" color="textSecondary">
      No call data found.
    </Typography>
    <Typography variant="body2" color="textSecondary">
      Select documents to view calls.
    </Typography>
  </Box>
);

type SortKey = keyof TradeCall;

export default function TradeRecommendations() {
  const [orderBy, setOrderBy] = useState<SortKey>("startDate");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const isLoading = false;

  // Generate and memoize dummy data
  const tradeCalls = useMemo(() => generateDummyTradeCalls(15), []);

  // Sort the calls
  const sortedCalls = useMemo(() => {
    return [...tradeCalls].sort((a, b) => {
      const comparison = (() => {
        if (orderBy === "startDate" || orderBy === "endDate") {
          return a[orderBy].getTime() - b[orderBy].getTime();
        }
        return String(a[orderBy]).localeCompare(String(b[orderBy]));
      })();

      return order === "asc" ? comparison : -comparison;
    });
  }, [tradeCalls, order, orderBy]);

  const handleSort = (property: SortKey) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper
          sx={{
            p: 3,
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "none",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              letterSpacing: "-0.02em",
            }}
          >
            Recent Trade Calls
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 440,
              border: "1px solid #e0e0e0",
              borderRadius: "12px",
              background: "linear-gradient(145deg, #ffffff, #f6f7f9)",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "firm"}
                      direction={orderBy === "firm" ? order : "asc"}
                      onClick={() => handleSort("firm")}
                    >
                      Firm
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "trade"}
                      direction={orderBy === "trade" ? order : "asc"}
                      onClick={() => handleSort("trade")}
                    >
                      Trade
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "startDate"}
                      direction={orderBy === "startDate" ? order : "asc"}
                      onClick={() => handleSort("startDate")}
                    >
                      Start Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "endDate"}
                      direction={orderBy === "endDate" ? order : "asc"}
                      onClick={() => handleSort("endDate")}
                    >
                      End Date
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <LoadingState />
                    </TableCell>
                  </TableRow>
                ) : sortedCalls.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <EmptyState />
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedCalls.map((call) => (
                    <TableRow
                      key={call.id}
                      sx={{
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#fafafa",
                        },
                      }}
                    >
                      <TableCell>{call.firm}</TableCell>
                      <TableCell>{call.trade}</TableCell>
                      <TableCell>
                        {format(call.startDate, "MMM dd, yyyy")}
                      </TableCell>
                      <TableCell>
                        {format(call.endDate, "MMM dd, yyyy")}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
}
