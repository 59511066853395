import * as React from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime, Settings } from "luxon";

Settings.defaultZone = "UTC";

interface MonthRangePickerProps {
  startMonth: Date;
  endMonth: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (startMonth: Date, endMonth: Date) => void;
}

const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
  startMonth: startMonthProp,
  endMonth: endMonthProp,
  minDate = new Date("2000-01-01"),
  maxDate = new Date("2100-12-31"),
  onChange,
}) => {
  // Convert initial props to Luxon DateTime instances in UTC
  const [startMonth, setStartMonth] = useState<DateTime>(
    DateTime.fromJSDate(startMonthProp, { zone: "utc" })
  );
  const [endMonth, setEndMonth] = useState<DateTime>(
    DateTime.fromJSDate(endMonthProp, { zone: "utc" })
  );

  const luxonMinDate = DateTime.fromJSDate(minDate, { zone: "utc" });
  const luxonMaxDate = DateTime.fromJSDate(maxDate, { zone: "utc" });

  const handleStartMonthChange = (date: DateTime | null) => {
    if (date === null || date.toISO() === startMonth.toISO()) return;
    setStartMonth(date);
    if (endMonth && endMonth < date)
      setEndMonth(date.plus({ months: 6 }).startOf("month"));
  };

  const handleEndMonthChange = (date: DateTime | null) => {
    if (date === null || date.toISO() === endMonth.toISO()) return;
    setEndMonth(date);
  };

  React.useEffect(() => {
    if (onChange) onChange(startMonth.toJSDate(), endMonth.toJSDate());
  }, [startMonth.toISO(), endMonth.toISO()]);

  const commonPickerProps: Partial<DatePickerProps<DateTime>> = {
    slotProps: {
      textField: {
        size: "small" as const,
        sx: {
          backgroundColor: "white",
          "& .MuiInputBase-root": {
            height: 40,
          },
        },
      },
      popper: {
        sx: {
          "& .MuiPaper-root": {
            backgroundColor: "white",
          },
          "& .MuiPickersLayout-root": {
            maxHeight: 350,
          },
        },
      },
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <DatePicker
          label="Start Month"
          views={["year", "month"]}
          timezone="UTC"
          value={startMonth}
          onChange={handleStartMonthChange}
          minDate={luxonMinDate}
          maxDate={luxonMaxDate}
          slotProps={{ textField: { sx: { mr: 2 } } }}
          {...commonPickerProps}
        />
        <DatePicker
          label="End Month"
          views={["year", "month"]}
          timezone="UTC"
          value={endMonth}
          onChange={handleEndMonthChange}
          minDate={startMonth.plus({ months: 1 })} // Ensure endMonth is after startMonth
          maxDate={luxonMaxDate}
          {...commonPickerProps}
          disabled={!startMonth.isValid}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default MonthRangePicker;
