import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { KeyTheme } from "../../generated/protos/api";
import Box from "@mui/material/Box";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useKeyThemesContext } from "../../context/KeyThemesContext";
import { useIsFetching } from "@tanstack/react-query";
import { LoaderCircle } from "lucide-react";

export interface KeyThemesProps {
  keyThemes: KeyTheme[];
}

const KeyThemeAccordion: React.FC<
  KeyTheme & { isFirst?: boolean; isLast?: boolean }
> = ({ themeDescription, supportingPoints, isFirst, isLast }) => {
  return (
    <Accordion
      disableGutters
      sx={{
        width: "100%",
        boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
        border: "1px solid rgba(0,0,0,0.08)",
        borderRadius: "12px !important",
        margin: "0 !important",
        "&:before": {
          display: "none",
        },
        "& .MuiAccordionSummary-root": {
          borderRadius: "12px",
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#fafafa",
          },
          transition: "all 0.3s ease",
          padding: "8px 16px",
          "& .MuiAccordionSummary-content": {
            margin: "8px 0 !important",
          },
        },
        "& .MuiAccordionDetails-root": {
          padding: "0px 16px 16px 48px",
        },
        "&.Mui-expanded": {
          borderRadius: "12px !important",
          margin: "0 !important",
          transform: "none !important",
        },
        transition: "none !important",
      }}
    >
      <AccordionSummary
        expandIcon={
          <KeyboardArrowRightIcon
            sx={{
              transform: "rotate(90deg)",
              transition: "transform 0.3s ease",
              color: "#2563eb",
              fontSize: "20px",
              alignSelf: "flex-start",
              ".Mui-expanded &": {
                transform: "rotate(90deg)",
              },
            }}
          />
        }
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "15px",
            color: "#1a1a1a",
            lineHeight: 1.4,
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {themeDescription}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box component="ul" sx={{ margin: 0, paddingLeft: "20px" }}>
          {supportingPoints.map((point, index) => (
            <Typography
              component="li"
              key={index}
              sx={{
                color: "#4b5563",
                marginBottom: "8px",
                lineHeight: 1.6,
                fontSize: "14px",
                "&:last-child": {
                  marginBottom: 0,
                },
                "&::marker": {
                  color: "#2563eb",
                },
              }}
            >
              {point}
            </Typography>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default function KeyThemeAccordions() {
  const { keyThemes } = useKeyThemesContext();
  const isFetchingKeyThemes = useIsFetching({ queryKey: ["keyThemes"], type: "all" });

  if (keyThemes.length === 0 && isFetchingKeyThemes === 0) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          position: "sticky",
          top: 0,
          zIndex: 1,
          padding: 2,
          paddingBottom: 0,
        }}
      >
        <LightbulbOutlinedIcon
          sx={{
            color: "#2563eb",
            fontSize: "24px",
          }}
        />
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            background: "linear-gradient(135deg, #2563eb, #4f46e5)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            letterSpacing: "-0.02em",
          }}
        >
          Key Themes
        </Typography>
        {isFetchingKeyThemes > 0 && (
          <LoaderCircle
            style={{
              color: "#2563eb",
              animation: "spin 1s linear infinite",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gap: "16px",
            width: "100%",
          }}
        >
          {keyThemes.map((keyTheme, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
              }}
            >
              <KeyThemeAccordion
                themeDescription={keyTheme.themeDescription}
                supportingPoints={keyTheme.supportingPoints}
                isFirst={index === 0}
                isLast={index === keyThemes.length - 1}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
