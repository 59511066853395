import React from "react";
import { Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

// Styled Paper component for the card
const StyledCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2.5,
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.08)",
  transition: "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out",
  overflow: "hidden",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Centers content horizontally
  width: "100%", // Ensures the card takes full width
  "&:hover": {
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.12)",
    transform: "translateY(-2px)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "4px",
    background: `linear-gradient(90deg, 
      ${theme.palette.primary.main}44 0%, 
      ${theme.palette.primary.light}44 100%
    )`,
  },
}));

// Content wrapper for consistent padding
const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "100%", // Ensures content takes full width
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Centers children horizontally
  "& > *": {
    // Targets direct children
    width: "100%", // Makes all direct children take full width
    maxWidth: "100%", // Prevents overflow
  },
  // Target table specifically if needed
  "& table": {
    width: "100%",
    maxWidth: "100%",
  },
  // Center the title and filters
  "& h2, & div": {
    textAlign: "center",
  },
}));

export const Card: React.FC<CardProps> = ({ children, className = "" }) => {
  return (
    <StyledCard className={className} elevation={0}>
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default Card;
