// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/document.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp";
import { Chunk } from "./chunk";

export const protobufPackage = "finllm";

export enum DocumentStatus {
  DOCUMENT_STATUS_UNSPECIFIED = 0,
  DOCUMENT_STATUS_PROCESSING = 1,
  DOCUMENT_STATUS_COMPLETED = 2,
  DOCUMENT_STATUS_ERROR = 3,
  DOCUMENT_STATUS_DELETED = 4,
  UNRECOGNIZED = -1,
}

export function documentStatusFromJSON(object: any): DocumentStatus {
  switch (object) {
    case 0:
    case "DOCUMENT_STATUS_UNSPECIFIED":
      return DocumentStatus.DOCUMENT_STATUS_UNSPECIFIED;
    case 1:
    case "DOCUMENT_STATUS_PROCESSING":
      return DocumentStatus.DOCUMENT_STATUS_PROCESSING;
    case 2:
    case "DOCUMENT_STATUS_COMPLETED":
      return DocumentStatus.DOCUMENT_STATUS_COMPLETED;
    case 3:
    case "DOCUMENT_STATUS_ERROR":
      return DocumentStatus.DOCUMENT_STATUS_ERROR;
    case 4:
    case "DOCUMENT_STATUS_DELETED":
      return DocumentStatus.DOCUMENT_STATUS_DELETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DocumentStatus.UNRECOGNIZED;
  }
}

export function documentStatusToJSON(object: DocumentStatus): string {
  switch (object) {
    case DocumentStatus.DOCUMENT_STATUS_UNSPECIFIED:
      return "DOCUMENT_STATUS_UNSPECIFIED";
    case DocumentStatus.DOCUMENT_STATUS_PROCESSING:
      return "DOCUMENT_STATUS_PROCESSING";
    case DocumentStatus.DOCUMENT_STATUS_COMPLETED:
      return "DOCUMENT_STATUS_COMPLETED";
    case DocumentStatus.DOCUMENT_STATUS_ERROR:
      return "DOCUMENT_STATUS_ERROR";
    case DocumentStatus.DOCUMENT_STATUS_DELETED:
      return "DOCUMENT_STATUS_DELETED";
    case DocumentStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DocumentMetadata {
  documentId: string;
  documentName: string;
  userEmail: string;
  documentStatus: DocumentStatus;
  asOfTimestamp: Date | undefined;
}

export interface Document {
  documentMetadata: DocumentMetadata | undefined;
  documentBytes: Uint8Array;
}

export interface DocumentExtraction {
  documentMetadata: DocumentMetadata | undefined;
  relationshipChunks: Chunk[];
  sourceChunks: Chunk[];
  keyThemes: string;
}

function createBaseDocumentMetadata(): DocumentMetadata {
  return { documentId: "", documentName: "", userEmail: "", documentStatus: 0, asOfTimestamp: undefined };
}

export const DocumentMetadata: MessageFns<DocumentMetadata> = {
  encode(message: DocumentMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.documentId !== "") {
      writer.uint32(10).string(message.documentId);
    }
    if (message.documentName !== "") {
      writer.uint32(18).string(message.documentName);
    }
    if (message.userEmail !== "") {
      writer.uint32(26).string(message.userEmail);
    }
    if (message.documentStatus !== 0) {
      writer.uint32(32).int32(message.documentStatus);
    }
    if (message.asOfTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.asOfTimestamp), writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DocumentMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.documentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documentName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userEmail = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.documentStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.asOfTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentMetadata {
    return {
      documentId: isSet(object.documentId) ? globalThis.String(object.documentId) : "",
      documentName: isSet(object.documentName) ? globalThis.String(object.documentName) : "",
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
      documentStatus: isSet(object.documentStatus) ? documentStatusFromJSON(object.documentStatus) : 0,
      asOfTimestamp: isSet(object.asOfTimestamp) ? fromJsonTimestamp(object.asOfTimestamp) : undefined,
    };
  },

  toJSON(message: DocumentMetadata): unknown {
    const obj: any = {};
    if (message.documentId !== "") {
      obj.documentId = message.documentId;
    }
    if (message.documentName !== "") {
      obj.documentName = message.documentName;
    }
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    if (message.documentStatus !== 0) {
      obj.documentStatus = documentStatusToJSON(message.documentStatus);
    }
    if (message.asOfTimestamp !== undefined) {
      obj.asOfTimestamp = message.asOfTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentMetadata>, I>>(base?: I): DocumentMetadata {
    return DocumentMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DocumentMetadata>, I>>(object: I): DocumentMetadata {
    const message = createBaseDocumentMetadata();
    message.documentId = object.documentId ?? "";
    message.documentName = object.documentName ?? "";
    message.userEmail = object.userEmail ?? "";
    message.documentStatus = object.documentStatus ?? 0;
    message.asOfTimestamp = object.asOfTimestamp ?? undefined;
    return message;
  },
};

function createBaseDocument(): Document {
  return { documentMetadata: undefined, documentBytes: new Uint8Array(0) };
}

export const Document: MessageFns<Document> = {
  encode(message: Document, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.documentMetadata !== undefined) {
      DocumentMetadata.encode(message.documentMetadata, writer.uint32(10).fork()).join();
    }
    if (message.documentBytes.length !== 0) {
      writer.uint32(18).bytes(message.documentBytes);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Document {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.documentMetadata = DocumentMetadata.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documentBytes = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Document {
    return {
      documentMetadata: isSet(object.documentMetadata) ? DocumentMetadata.fromJSON(object.documentMetadata) : undefined,
      documentBytes: isSet(object.documentBytes) ? bytesFromBase64(object.documentBytes) : new Uint8Array(0),
    };
  },

  toJSON(message: Document): unknown {
    const obj: any = {};
    if (message.documentMetadata !== undefined) {
      obj.documentMetadata = DocumentMetadata.toJSON(message.documentMetadata);
    }
    if (message.documentBytes.length !== 0) {
      obj.documentBytes = base64FromBytes(message.documentBytes);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Document>, I>>(base?: I): Document {
    return Document.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Document>, I>>(object: I): Document {
    const message = createBaseDocument();
    message.documentMetadata = (object.documentMetadata !== undefined && object.documentMetadata !== null)
      ? DocumentMetadata.fromPartial(object.documentMetadata)
      : undefined;
    message.documentBytes = object.documentBytes ?? new Uint8Array(0);
    return message;
  },
};

function createBaseDocumentExtraction(): DocumentExtraction {
  return { documentMetadata: undefined, relationshipChunks: [], sourceChunks: [], keyThemes: "" };
}

export const DocumentExtraction: MessageFns<DocumentExtraction> = {
  encode(message: DocumentExtraction, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.documentMetadata !== undefined) {
      DocumentMetadata.encode(message.documentMetadata, writer.uint32(10).fork()).join();
    }
    for (const v of message.relationshipChunks) {
      Chunk.encode(v!, writer.uint32(18).fork()).join();
    }
    for (const v of message.sourceChunks) {
      Chunk.encode(v!, writer.uint32(26).fork()).join();
    }
    if (message.keyThemes !== "") {
      writer.uint32(34).string(message.keyThemes);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DocumentExtraction {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentExtraction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.documentMetadata = DocumentMetadata.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.relationshipChunks.push(Chunk.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sourceChunks.push(Chunk.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.keyThemes = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentExtraction {
    return {
      documentMetadata: isSet(object.documentMetadata) ? DocumentMetadata.fromJSON(object.documentMetadata) : undefined,
      relationshipChunks: globalThis.Array.isArray(object?.relationshipChunks)
        ? object.relationshipChunks.map((e: any) => Chunk.fromJSON(e))
        : [],
      sourceChunks: globalThis.Array.isArray(object?.sourceChunks)
        ? object.sourceChunks.map((e: any) => Chunk.fromJSON(e))
        : [],
      keyThemes: isSet(object.keyThemes) ? globalThis.String(object.keyThemes) : "",
    };
  },

  toJSON(message: DocumentExtraction): unknown {
    const obj: any = {};
    if (message.documentMetadata !== undefined) {
      obj.documentMetadata = DocumentMetadata.toJSON(message.documentMetadata);
    }
    if (message.relationshipChunks?.length) {
      obj.relationshipChunks = message.relationshipChunks.map((e) => Chunk.toJSON(e));
    }
    if (message.sourceChunks?.length) {
      obj.sourceChunks = message.sourceChunks.map((e) => Chunk.toJSON(e));
    }
    if (message.keyThemes !== "") {
      obj.keyThemes = message.keyThemes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentExtraction>, I>>(base?: I): DocumentExtraction {
    return DocumentExtraction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DocumentExtraction>, I>>(object: I): DocumentExtraction {
    const message = createBaseDocumentExtraction();
    message.documentMetadata = (object.documentMetadata !== undefined && object.documentMetadata !== null)
      ? DocumentMetadata.fromPartial(object.documentMetadata)
      : undefined;
    message.relationshipChunks = object.relationshipChunks?.map((e) => Chunk.fromPartial(e)) || [];
    message.sourceChunks = object.sourceChunks?.map((e) => Chunk.fromPartial(e)) || [];
    message.keyThemes = object.keyThemes ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
