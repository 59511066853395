
import { startOfMonth, addMinutes } from 'date-fns';

export type Timeseries = {
	value: string;
	asOfTimestamp: Date | undefined;
}[] | undefined;
  
export const processTimeseries = (timeseries: Timeseries, startDate: Date, endDate: Date) => timeseries
	?.filter((x): x is { value: string; asOfTimestamp: Date; } => x.asOfTimestamp !== undefined) // Filter out entries that don't have timestamps
	.map(entry => ({ ...entry, value: parseFloat(entry.value) })) // Parse the value
	.filter(entry => entry.asOfTimestamp?.getTime() >= startDate.getTime()) // Filter out dates older then the start date
	.filter(entry => entry.asOfTimestamp?.getTime() <= endDate.getTime()) // Filter out dates newer than the end date
	.map(entry => ({ ...entry, month: entry.asOfTimestamp.toLocaleString("en-US", { timeZone: 'UTC', year: "numeric", month: "long" }) })) // Format the dates for display
	?? []; // Return an empty array by default

export const average = (numbers: number[]): number | null => {
	if (numbers.length === 0) return null;
	const sum = numbers.reduce((acc: number, num: number) => acc + num, 0);
	return sum / numbers.length;
};

export const startOfMonthUTC = (date: Date): Date => {
	const localStartOfMonth = startOfMonth(date);
	return addMinutes(localStartOfMonth, -localStartOfMonth.getTimezoneOffset());
};