// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/analytics.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "finllm";

export interface TimeSeriesDataPoint {
  value: string;
  asOfTimestamp: Date | undefined;
}

export interface MetricAnalytics {
  metricName: string;
  accuracyAggregate: string;
  errorBound: string;
  predictionsTimeseries: TimeSeriesDataPoint[];
  accuracyTimeseries: TimeSeriesDataPoint[];
  accuracyBoxplot: Boxplot | undefined;
}

export interface BankAnalytics {
  bank: string;
  metricAnalytics: MetricAnalytics[];
}

export interface HistoricalMetricData {
  metricName: string;
  timeSeriesData: TimeSeriesDataPoint[];
}

export interface Point {
  x: string;
  y: string;
}

export interface EnsemblePredictionDistribution {
  metricName: string;
  distributionCurvePoints: Point[];
  mean: string;
  stdDev: string;
  timestamp: Date | undefined;
}

export interface Boxplot {
  min: string;
  q1: string;
  median: string;
  q3: string;
  max: string;
}

function createBaseTimeSeriesDataPoint(): TimeSeriesDataPoint {
  return { value: "", asOfTimestamp: undefined };
}

export const TimeSeriesDataPoint: MessageFns<TimeSeriesDataPoint> = {
  encode(message: TimeSeriesDataPoint, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.asOfTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.asOfTimestamp), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TimeSeriesDataPoint {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeSeriesDataPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.asOfTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeSeriesDataPoint {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      asOfTimestamp: isSet(object.asOfTimestamp) ? fromJsonTimestamp(object.asOfTimestamp) : undefined,
    };
  },

  toJSON(message: TimeSeriesDataPoint): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.asOfTimestamp !== undefined) {
      obj.asOfTimestamp = message.asOfTimestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeSeriesDataPoint>, I>>(base?: I): TimeSeriesDataPoint {
    return TimeSeriesDataPoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesDataPoint>, I>>(object: I): TimeSeriesDataPoint {
    const message = createBaseTimeSeriesDataPoint();
    message.value = object.value ?? "";
    message.asOfTimestamp = object.asOfTimestamp ?? undefined;
    return message;
  },
};

function createBaseMetricAnalytics(): MetricAnalytics {
  return {
    metricName: "",
    accuracyAggregate: "",
    errorBound: "",
    predictionsTimeseries: [],
    accuracyTimeseries: [],
    accuracyBoxplot: undefined,
  };
}

export const MetricAnalytics: MessageFns<MetricAnalytics> = {
  encode(message: MetricAnalytics, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.metricName !== "") {
      writer.uint32(10).string(message.metricName);
    }
    if (message.accuracyAggregate !== "") {
      writer.uint32(18).string(message.accuracyAggregate);
    }
    if (message.errorBound !== "") {
      writer.uint32(26).string(message.errorBound);
    }
    for (const v of message.predictionsTimeseries) {
      TimeSeriesDataPoint.encode(v!, writer.uint32(34).fork()).join();
    }
    for (const v of message.accuracyTimeseries) {
      TimeSeriesDataPoint.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.accuracyBoxplot !== undefined) {
      Boxplot.encode(message.accuracyBoxplot, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MetricAnalytics {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetricAnalytics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metricName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accuracyAggregate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorBound = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.predictionsTimeseries.push(TimeSeriesDataPoint.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accuracyTimeseries.push(TimeSeriesDataPoint.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.accuracyBoxplot = Boxplot.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MetricAnalytics {
    return {
      metricName: isSet(object.metricName) ? globalThis.String(object.metricName) : "",
      accuracyAggregate: isSet(object.accuracyAggregate) ? globalThis.String(object.accuracyAggregate) : "",
      errorBound: isSet(object.errorBound) ? globalThis.String(object.errorBound) : "",
      predictionsTimeseries: globalThis.Array.isArray(object?.predictionsTimeseries)
        ? object.predictionsTimeseries.map((e: any) => TimeSeriesDataPoint.fromJSON(e))
        : [],
      accuracyTimeseries: globalThis.Array.isArray(object?.accuracyTimeseries)
        ? object.accuracyTimeseries.map((e: any) => TimeSeriesDataPoint.fromJSON(e))
        : [],
      accuracyBoxplot: isSet(object.accuracyBoxplot) ? Boxplot.fromJSON(object.accuracyBoxplot) : undefined,
    };
  },

  toJSON(message: MetricAnalytics): unknown {
    const obj: any = {};
    if (message.metricName !== "") {
      obj.metricName = message.metricName;
    }
    if (message.accuracyAggregate !== "") {
      obj.accuracyAggregate = message.accuracyAggregate;
    }
    if (message.errorBound !== "") {
      obj.errorBound = message.errorBound;
    }
    if (message.predictionsTimeseries?.length) {
      obj.predictionsTimeseries = message.predictionsTimeseries.map((e) => TimeSeriesDataPoint.toJSON(e));
    }
    if (message.accuracyTimeseries?.length) {
      obj.accuracyTimeseries = message.accuracyTimeseries.map((e) => TimeSeriesDataPoint.toJSON(e));
    }
    if (message.accuracyBoxplot !== undefined) {
      obj.accuracyBoxplot = Boxplot.toJSON(message.accuracyBoxplot);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MetricAnalytics>, I>>(base?: I): MetricAnalytics {
    return MetricAnalytics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MetricAnalytics>, I>>(object: I): MetricAnalytics {
    const message = createBaseMetricAnalytics();
    message.metricName = object.metricName ?? "";
    message.accuracyAggregate = object.accuracyAggregate ?? "";
    message.errorBound = object.errorBound ?? "";
    message.predictionsTimeseries = object.predictionsTimeseries?.map((e) => TimeSeriesDataPoint.fromPartial(e)) || [];
    message.accuracyTimeseries = object.accuracyTimeseries?.map((e) => TimeSeriesDataPoint.fromPartial(e)) || [];
    message.accuracyBoxplot = (object.accuracyBoxplot !== undefined && object.accuracyBoxplot !== null)
      ? Boxplot.fromPartial(object.accuracyBoxplot)
      : undefined;
    return message;
  },
};

function createBaseBankAnalytics(): BankAnalytics {
  return { bank: "", metricAnalytics: [] };
}

export const BankAnalytics: MessageFns<BankAnalytics> = {
  encode(message: BankAnalytics, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bank !== "") {
      writer.uint32(10).string(message.bank);
    }
    for (const v of message.metricAnalytics) {
      MetricAnalytics.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BankAnalytics {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAnalytics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bank = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metricAnalytics.push(MetricAnalytics.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAnalytics {
    return {
      bank: isSet(object.bank) ? globalThis.String(object.bank) : "",
      metricAnalytics: globalThis.Array.isArray(object?.metricAnalytics)
        ? object.metricAnalytics.map((e: any) => MetricAnalytics.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BankAnalytics): unknown {
    const obj: any = {};
    if (message.bank !== "") {
      obj.bank = message.bank;
    }
    if (message.metricAnalytics?.length) {
      obj.metricAnalytics = message.metricAnalytics.map((e) => MetricAnalytics.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BankAnalytics>, I>>(base?: I): BankAnalytics {
    return BankAnalytics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BankAnalytics>, I>>(object: I): BankAnalytics {
    const message = createBaseBankAnalytics();
    message.bank = object.bank ?? "";
    message.metricAnalytics = object.metricAnalytics?.map((e) => MetricAnalytics.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHistoricalMetricData(): HistoricalMetricData {
  return { metricName: "", timeSeriesData: [] };
}

export const HistoricalMetricData: MessageFns<HistoricalMetricData> = {
  encode(message: HistoricalMetricData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.metricName !== "") {
      writer.uint32(10).string(message.metricName);
    }
    for (const v of message.timeSeriesData) {
      TimeSeriesDataPoint.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HistoricalMetricData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalMetricData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metricName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeSeriesData.push(TimeSeriesDataPoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalMetricData {
    return {
      metricName: isSet(object.metricName) ? globalThis.String(object.metricName) : "",
      timeSeriesData: globalThis.Array.isArray(object?.timeSeriesData)
        ? object.timeSeriesData.map((e: any) => TimeSeriesDataPoint.fromJSON(e))
        : [],
    };
  },

  toJSON(message: HistoricalMetricData): unknown {
    const obj: any = {};
    if (message.metricName !== "") {
      obj.metricName = message.metricName;
    }
    if (message.timeSeriesData?.length) {
      obj.timeSeriesData = message.timeSeriesData.map((e) => TimeSeriesDataPoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalMetricData>, I>>(base?: I): HistoricalMetricData {
    return HistoricalMetricData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalMetricData>, I>>(object: I): HistoricalMetricData {
    const message = createBaseHistoricalMetricData();
    message.metricName = object.metricName ?? "";
    message.timeSeriesData = object.timeSeriesData?.map((e) => TimeSeriesDataPoint.fromPartial(e)) || [];
    return message;
  },
};

function createBasePoint(): Point {
  return { x: "", y: "" };
}

export const Point: MessageFns<Point> = {
  encode(message: Point, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== "") {
      writer.uint32(10).string(message.x);
    }
    if (message.y !== "") {
      writer.uint32(18).string(message.y);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Point {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.x = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.y = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Point {
    return {
      x: isSet(object.x) ? globalThis.String(object.x) : "",
      y: isSet(object.y) ? globalThis.String(object.y) : "",
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    if (message.x !== "") {
      obj.x = message.x;
    }
    if (message.y !== "") {
      obj.y = message.y;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.x = object.x ?? "";
    message.y = object.y ?? "";
    return message;
  },
};

function createBaseEnsemblePredictionDistribution(): EnsemblePredictionDistribution {
  return { metricName: "", distributionCurvePoints: [], mean: "", stdDev: "", timestamp: undefined };
}

export const EnsemblePredictionDistribution: MessageFns<EnsemblePredictionDistribution> = {
  encode(message: EnsemblePredictionDistribution, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.metricName !== "") {
      writer.uint32(10).string(message.metricName);
    }
    for (const v of message.distributionCurvePoints) {
      Point.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.mean !== "") {
      writer.uint32(26).string(message.mean);
    }
    if (message.stdDev !== "") {
      writer.uint32(34).string(message.stdDev);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): EnsemblePredictionDistribution {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnsemblePredictionDistribution();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metricName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.distributionCurvePoints.push(Point.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mean = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stdDev = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnsemblePredictionDistribution {
    return {
      metricName: isSet(object.metricName) ? globalThis.String(object.metricName) : "",
      distributionCurvePoints: globalThis.Array.isArray(object?.distributionCurvePoints)
        ? object.distributionCurvePoints.map((e: any) => Point.fromJSON(e))
        : [],
      mean: isSet(object.mean) ? globalThis.String(object.mean) : "",
      stdDev: isSet(object.stdDev) ? globalThis.String(object.stdDev) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: EnsemblePredictionDistribution): unknown {
    const obj: any = {};
    if (message.metricName !== "") {
      obj.metricName = message.metricName;
    }
    if (message.distributionCurvePoints?.length) {
      obj.distributionCurvePoints = message.distributionCurvePoints.map((e) => Point.toJSON(e));
    }
    if (message.mean !== "") {
      obj.mean = message.mean;
    }
    if (message.stdDev !== "") {
      obj.stdDev = message.stdDev;
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnsemblePredictionDistribution>, I>>(base?: I): EnsemblePredictionDistribution {
    return EnsemblePredictionDistribution.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnsemblePredictionDistribution>, I>>(
    object: I,
  ): EnsemblePredictionDistribution {
    const message = createBaseEnsemblePredictionDistribution();
    message.metricName = object.metricName ?? "";
    message.distributionCurvePoints = object.distributionCurvePoints?.map((e) => Point.fromPartial(e)) || [];
    message.mean = object.mean ?? "";
    message.stdDev = object.stdDev ?? "";
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseBoxplot(): Boxplot {
  return { min: "", q1: "", median: "", q3: "", max: "" };
}

export const Boxplot: MessageFns<Boxplot> = {
  encode(message: Boxplot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.min !== "") {
      writer.uint32(10).string(message.min);
    }
    if (message.q1 !== "") {
      writer.uint32(18).string(message.q1);
    }
    if (message.median !== "") {
      writer.uint32(26).string(message.median);
    }
    if (message.q3 !== "") {
      writer.uint32(34).string(message.q3);
    }
    if (message.max !== "") {
      writer.uint32(42).string(message.max);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Boxplot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoxplot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.min = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.q1 = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.median = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.q3 = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.max = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Boxplot {
    return {
      min: isSet(object.min) ? globalThis.String(object.min) : "",
      q1: isSet(object.q1) ? globalThis.String(object.q1) : "",
      median: isSet(object.median) ? globalThis.String(object.median) : "",
      q3: isSet(object.q3) ? globalThis.String(object.q3) : "",
      max: isSet(object.max) ? globalThis.String(object.max) : "",
    };
  },

  toJSON(message: Boxplot): unknown {
    const obj: any = {};
    if (message.min !== "") {
      obj.min = message.min;
    }
    if (message.q1 !== "") {
      obj.q1 = message.q1;
    }
    if (message.median !== "") {
      obj.median = message.median;
    }
    if (message.q3 !== "") {
      obj.q3 = message.q3;
    }
    if (message.max !== "") {
      obj.max = message.max;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Boxplot>, I>>(base?: I): Boxplot {
    return Boxplot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Boxplot>, I>>(object: I): Boxplot {
    const message = createBaseBoxplot();
    message.min = object.min ?? "";
    message.q1 = object.q1 ?? "";
    message.median = object.median ?? "";
    message.q3 = object.q3 ?? "";
    message.max = object.max ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
